import React, { FormEvent, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faKey, faSync } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Polígono } from '../../assets/Polígono.svg';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import {
  resetParams,
  resetStateHelper,
  setStatusSenha,
  toggleRecalcular,
} from '../../services/reducers/preferenceSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksResumoCart} from '../../services/reducers/resumoCartSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksPerfHist} from '../../services/reducers/perfHistSlice';
import { CustomModal } from '../CustomModal';
import MudarSenha from '../Login/MudarSenha';
import { changePassword } from '../../services/api/preferenceAPI';
import {cancelarAPI} from '../../services/api/abortAPI';
import { fetchPreference } from '../../services/api/preferenceAPI';
import BotaoLogout from './BotaoLogout'

export default function BasicMenu() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  // geral
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [loadingTrocarSenha, trocarSenhaStatus, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.loadingTrocarSenha,
    state.preference.trocarSenhaStatus,
    state.loadMinimized.isLoadingMinimized,
  ]);

  const handleAtualizaFlag = () => {
    setTimeout(() => {
      localStorage.setItem('flagTrocaSenha', '')
      window.location.reload();
    }, 1200)
  };

  // botão de recalcular
  const handleRecalcular = () => {
    dispatch(toggleRecalcular());
    dispatch(resetStateHelper());
    handleClose();
  };

  // botão de trocar senha
  const [openModal, setOpenModal] = useState(false);
  const [openModalAviso, setOpenModalAviso] = useState(false);
  const [aviso, setAviso] = useState('');
  const [error, setError] = useState(false);
  const handleOpenTrocarSenha = () => {
    setOpenModal(true);
    setError(false);
    handleClose();
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement);
    const password = formData.get('novaSenha') as string;
    const password2 = formData.get('confirmeNovaSenha') as string;
    if (password !== password2 || !password) setError(true);
    else {
      localStorage.setItem('senhaLogin', password2)
      dispatch(changePassword(password));
    }
  };

  useEffect(() => {
    if (trocarSenhaStatus === 'ok') {
      const emailLogin = localStorage.getItem('emailLogin')
      const password = localStorage.getItem('senhaLogin')
      if (typeof emailLogin === 'string' && typeof password === 'string') {
        dispatch(fetchPreference({ username: emailLogin, password }));
      }
      setAviso('Senha trocada com sucesso!');
      setOpenModal(false);
      setOpenModalAviso(true);
      dispatch(setStatusSenha('none'));
      handleAtualizaFlag()
    }
  }, [trocarSenhaStatus]);

  return (
    <>
      <div>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <User style={{ marginLeft: 32 }} fill={theme.menuIconsColor} />
          <Polígono style={{ marginLeft: 6 }} fill={theme.menuIconsColor} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}>
          <BotaoLogout setAnchorEl={setAnchorEl} />
          <MenuItem onClick={handleRecalcular}>
            <FontAwesomeIcon icon={faSync as IconProp} style={{ marginRight: 8 }} />
            Recalcular
          </MenuItem>
          <MenuItem onClick={handleOpenTrocarSenha}>
            <FontAwesomeIcon icon={faKey as IconProp} style={{ marginRight: 8 }} />
            Mudar senha
          </MenuItem>
        </Menu>
      </div>
      <CustomModal
        open={openModal}
        onCloseHandle={() => setOpenModal(false)}
        minWidth={300}
        maxWidth={500}>
        <MudarSenha
          loading={loadingTrocarSenha}
          error={error}
          handleSubmit={handleSubmit}
          status={trocarSenhaStatus}
        />
      </CustomModal>
      <CustomModal
        open={openModalAviso}
        onCloseHandle={() => setOpenModalAviso(false)}
        minWidth={300}
        maxWidth={500}>
        {aviso}
      </CustomModal>
    </>
  );
}
