import styled from 'styled-components';

export const BotaoContainer = styled.div<{ floatLeft?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.fontColor};
  flex-direction: column;
  ${(props) => props.floatLeft ? "float: left;" : ""}
`;

export const HeaderContainerStyle = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 24px;
  flex-wrap: wrap;
  @media ${(props) => props.theme.device.tablet} {
    margin-top: 8px;
  }
`;
