import styled from 'styled-components';

export const StyledResponsiveContainer = styled.div`
  position: relative;
  height: auto;
`;

export const ChartContainer = styled.div`
  height: 278px;
`;

export const LegendContainer = styled.div`
  height: auto;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-left: 20px;
`;
