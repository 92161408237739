let controller = new AbortController();
let signal = controller.signal;

/**
 * A��o para cancelar a chamada da API
 */
export const cancelarAPI = () => {
    controller.abort();
    // a classe AbortController pode abortar apenas uma vez, ent�o � necess�rio criar uma nova inst�ncia
    controller = new AbortController();
    signal = controller.signal;
}

export { signal }