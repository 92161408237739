import { fetchCartExp } from './../api/cartExpAPI';
import { resetStateBuilder } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAtivosAgrupados {
  nome_ativo: string;
  classe: string;
  subclasse: string;
  tipo_ativo: string;
  sbr_fim: string;
  percent_sbr: string;
  ticker: string;
}

export interface ICartExpState {
  isLoaded: boolean;
  loading: boolean;
  dados_cart_explodida: IAtivosAgrupados[];
}

const initialState: ICartExpState = {
  isLoaded: false,
  loading: false,
  dados_cart_explodida: [],
};

export const cartExpSlice = createSlice({
  name: 'cartExp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState);

    builder.addCase(fetchCartExp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCartExp.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.dados_cart_explodida = action.payload.CartExplodida?.dados_cart_explodida;
    });
    builder.addCase(fetchCartExp.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default cartExpSlice.reducer;
