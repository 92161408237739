import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import { ReactComponent as Search } from '../../../../assets/search.svg';
import { InputAdornment } from '@mui/material';

interface Props {
  carteiraPesquisada: string;
  setCarteiraPesquisada: React.Dispatch<React.SetStateAction<string>>;
}

export default function ComboBox({
  carteiraPesquisada,
  setCarteiraPesquisada,
}: Props) {
  return (
    <Autocomplete
      freeSolo
      options={[]}
      sx={{
        width: 328,
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none',
        },
      }}
      clearIcon={null}
      autoComplete={false}
      onInputChange={(event, value) => setCarteiraPesquisada(value)}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          autoComplete='off'
          placeholder="Pesquisar"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search style={{ marginRight: carteiraPesquisada.length === 0 ? 15 : -30 }} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
