import React, { useState, FormEvent } from 'react';
import { Grid, IconButton, InputAdornment, Skeleton, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import {
  Form,
  LoginErrorContainer,
  ContainerMudarSenha,
  MudarSenhaTitle,
  ButtonTrocarSenha,
  SenhaFracaContainer,
} from './style';
import { Visibility, VisibilityOff } from '@mui/icons-material';

interface IProps {
  loading: boolean;
  error: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => any;
  status: 'ok' | 'error' | 'none';
}

const MudarSenha = ({ loading, error, handleSubmit, status }: IProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);
  return (
    <ContainerMudarSenha>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MudarSenhaTitle>Mude sua senha</MudarSenhaTitle>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="novaSenha"
              name="novaSenha"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoFocus
              label={
                <span>
                  <FontAwesomeIcon icon={faLock as IconProp} style={{ marginRight: 12 }} />
                  Nova senha
                </span>
              }
              fullWidth
              variant="standard"></TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="confirmeNovaSenha"
              name="confirmeNovaSenha"
              type={showConfirmPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              label={
                <span>
                  <FontAwesomeIcon icon={faLock as IconProp} style={{ marginRight: 12 }} />
                  Confirme a nova senha
                </span>
              }
              fullWidth
              variant="standard"></TextField>
          </Grid>
          {!error ? null : (
            <Grid item xs={12}>
              <LoginErrorContainer>
                Falha ao trocar a senha. Verifique se os campos contém o mesmo valor.
              </LoginErrorContainer>
            </Grid>
          )}
          {status !== 'error' ? null : (
            <Grid item xs={12}>
              <SenhaFracaContainer>
                Senha fraca! Uma senha forte satisfaz todos os atributos a seguir:
                <br />
                <div>
                  - Possuir entre 8 e 128 caracteres; <br />
                  - Possuir um ou mais caracteres maiúsculos; <br />
                  - Possuir um ou mais caracteres minúsculos; <br />
                  - Possuir um ou mais caracteres numéricos; <br />
                  - Possuir um ou mais caracteres especiais; <br />
                </div>
              </SenhaFracaContainer>
            </Grid>
          )}
          <Grid item xs={12}>
            <ButtonTrocarSenha type="submit">
              {loading ? <Skeleton width={60} height={24} /> : 'Trocar senha'}
            </ButtonTrocarSenha>
          </Grid>
        </Grid>
      </Form>
    </ContainerMudarSenha>
  );
};

export default MudarSenha;
