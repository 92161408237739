import styled from 'styled-components';

export const Container = styled.div<{ containerHeight: number }>`
  position: absolute;
  padding: ${({ theme }) => theme.mdPadding};
  font-size: ${({ theme }) => theme.mdFontSize};
  margin-top: ${({ containerHeight }) => containerHeight + 40}px;
  right: 24px;
  border-radius: 5px;
  border-color: ${({ theme }) => theme.fontColor};
  background-color: ${({ theme }) => theme.backgroundColorSecondary};
  color: ${({ theme }) => theme.fontColor};

  @media ${(props) => props.theme.device.mobileM} {
    font-size: ${(props) => props.theme.smFontSize};
    padding: ${(props) => props.theme.smPadding};
  }
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
    padding: ${(props) => props.theme.xsPadding};
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const Button = styled.div`
  padding: 5px;
  margin-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.fontColor};
  color: ${({ theme }) => theme.backgroundColorSecondary};

  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
  }
  @media ${(props) => props.theme.device.mobileM} {
    font-size: ${(props) => props.theme.xsFontSize};
  }
`;
