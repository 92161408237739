import React from 'react';
import { Breadcrumbs, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import useIsMobile from '../../services/useIsMobile';

interface BreadcrumbProps {
  screen: string;
}

export default function Breadcrumb({ screen }: BreadcrumbProps) {
  const isMobile = useIsMobile();
  const { search, pathname } = useLocation();
  const theme = useTheme();

  const breadcrumbs = [
    <Link
      key="1"
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={{ pathname: '/', search }}
      state={pathname}>
      Home
    </Link>,
    <Typography
      key="2"
      color="inherit"
      sx={{ fontSize: isMobile ? theme.smFontSize : theme.mdFontSize }}>
      {screen}
    </Typography>,
  ];
  return (
    <Breadcrumbs
      sx={{ color: theme.fontColor, fontSize: isMobile ? theme.smFontSize : theme.mdFontSize }}
      separator=">">
      {breadcrumbs}
    </Breadcrumbs>
  );
}
