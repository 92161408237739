import React from 'react';
import { LineSmallStyle, LineStyle } from './style';

export function LineSmall() {
  return <LineSmallStyle />;
}

export function Line() {
  return <LineStyle />;
}
