import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useTheme } from 'styled-components';
import useIsMobile from '../../../src/services/useIsMobile';
import { helperFormat, FormatTypes } from '../../../src/services/helper';
import { StyledResponsiveContainer, ChartContainer, LegendContainer } from './style';
interface Props {
  tipo: FormatTypes;
  assets: string[] | null;
  data: any;
  moneyPrefix: string | null;
  handleOnAnimationEnd?: () => void;
}

export default function GraficoLinha({
  data,
  tipo,
  assets,
  moneyPrefix,
  handleOnAnimationEnd,
}: Props) {
  const theme = useTheme();
  const isMobile = useIsMobile();

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload) {
      return (
        <div
          style={{ backgroundColor: theme.backgroundColorSecondary, borderRadius: 5, padding: 10 }}>
          {payload.map((item: any, i: number) => {
            return (
              <div key={Math.random()}>
                <div style={{ fontWeight: 'bold' }}>{i === 0 && item.payload.datesTooltip}</div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div
                    style={{ width: 10, height: 10, backgroundColor: item.stroke, marginRight: 5 }}
                  />
                  <div>{`${
                    item.name === 'Carteira_retorno' ? 'Carteira' : item.name
                  } : ${helperFormat(item.value, tipo, 2)}`}</div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  };

  return data && data.length > 0 && assets ? (
    <StyledResponsiveContainer>
      <ChartContainer>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 15,
              bottom: 10,
            }}>
            <CartesianGrid strokeDasharray="1 0" vertical={false} />
            <XAxis dataKey="name" tickLine={false} axisLine={false} tickMargin={15} />
            <YAxis
              width={!isMobile && tipo === 'percentage' ? 45 : 75}
              tickLine={false}
              tickFormatter={(value) => {
                return `${helperFormat(value, tipo, 1, moneyPrefix)}`;
              }}
              axisLine={false}
            />
            <Tooltip wrapperStyle={{ outline: 'none' }} content={<CustomTooltip />} />
            {assets?.map((item: string, i: number) => (
              <Line
                onAnimationEnd={handleOnAnimationEnd}
                type="monotone"
                key={item}
                dataKey={item}
                stroke={theme.lineChartColors[i % theme.lineChartColors.length]}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </ChartContainer>
      <LegendContainer>
        {assets.map((value, i) => (
          <div key={value} style={{ marginRight: 15 }}>
            <span
              style={{
                display: 'inline-block',
                width: 13,
                height: 13,
                backgroundColor: theme.lineChartColors[i % theme.lineChartColors.length],
                marginRight: 4,
                marginTop: 12,
              }}
            />
            <span style={{ color: 'black', position: 'relative', top: '-2px' }}>
              {value === 'Carteira_retorno' ? 'Carteira' : value}
            </span>
          </div>
        ))}
      </LegendContainer>
    </StyledResponsiveContainer>
  ) : null;
}
