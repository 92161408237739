import React, { ReactNode } from 'react';
import { ContainerTitle, ContainerSubTitle, ContainerSmallerTitle } from './style';
import { useTheme } from 'styled-components';

interface TituloProps {
  children: ReactNode;
  mb?: string;
  center?: boolean;
}

export default function Titulo(props: TituloProps) {
  return <ContainerTitle>{props.children}</ContainerTitle>;
}

export const Subtitulo = (props: TituloProps) => {
  return (
    <ContainerSubTitle smallerFontSize mb={props.mb} center={props.center}>
      {props.children}
    </ContainerSubTitle>
  );
};

export const SmallerTitle = (props: TituloProps) => {
  return <ContainerSmallerTitle>{props.children}</ContainerSmallerTitle>;
}

export const CartCounter = (props: TituloProps) => {
  const theme = useTheme();
  return <ContainerSmallerTitle style={{color: theme.lineColor}}>{props.children}</ContainerSmallerTitle>;
}
