import React, { useEffect, useCallback } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAppSelector } from '../../services/reduxHooks';
import { checkPeriodoPersonalizado, compareDatesDDMMYYYY } from '../../services/helper';

// export const useIsDisabled = (config?: {type?: 'ini'|'fim'; calendarioUnico?: boolean; datas?: {data_ini?: any; data_fim?: any;}}) => {
export const useIsDisabled = (config?: any) => {
  const [carteiraList, loadedCarteiraList, carteira, periodo] = useAppSelector((state) => [
    state.preference.carteiraList,
    state.preference.loadedCarteiraList,
    state.preference.params.carteira,
    state.preference.params.periodo,
  ]);

  let carteiraListAtual = carteiraList

  if (loadedCarteiraList && carteiraList && carteiraList[0] && carteira) {
    carteiraListAtual = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase());
  }

let compareDates = 0;
let dateToCompare: any = null;
  if (!config?.calendarioUnico) {
    if(config?.type === 'ini') {
      compareDates = 1;
      dateToCompare = config?.datas?.data_fim;
    }
    if(config?.type === 'fim') {
      compareDates = -1;
      dateToCompare = config?.datas?.data_ini;
    }
  }

  const isDisabled = useCallback((date: Dayjs) => {
    const day = date.day();
    const dates = date.format('YYYY-MM-DD');
    const dateDDMMYYYY = date.format('DDMMYYYY');
    const dataTemp = new Date(dates);
    let dataLimiteMin = new Date('1999-01-01');
    let dataLimiteMax = new Date('2099-12-31');
    if (carteiraListAtual && carteiraListAtual[0]) {
      dataLimiteMin = new Date(
        carteiraListAtual[0].data_mais_antiga_portfolio_AAAAmmDD,
      );
      dataLimiteMax = new Date(
        carteiraListAtual[0].data_mais_recente_portfolio_AAAAmmDD,
      );
    }
    return (
      day === 0 ||
      day === 6 ||
      carteiraListAtual[0]?.datas_nao_uteis.includes(dates) ||
      dataTemp > dataLimiteMax ||
      dataTemp < dataLimiteMin ||
      (compareDates > 0 && compareDatesDDMMYYYY(dateToCompare, dateDDMMYYYY) <= 0) ||
      (compareDates < 0 && compareDatesDDMMYYYY(dateToCompare, dateDDMMYYYY) >= 0)
    );
  }, [config]);

  return isDisabled;
}