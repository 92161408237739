import styled from 'styled-components';
import Box from '@mui/material/Box';
interface IModalProps {
  minWidth?: number;
  maxWidth?: number;
  width?: string;
}

export const StyledModalContainer = styled(Box)<IModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.backgroundColor};
  min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : '')};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '')};
  width: ${(props) => (props.width ? props.width : '80%')};
  max-height: 80%;
  border-radius: 12px;
  outline: none;
  overflow-x: auto;

  padding: ${({ theme }) => theme.mdPadding};
  font-size: ${({ theme }) => theme.mdFontSize};
  @media ${(props) => props.theme.device.mobileM} {
    width: 80%;
    font-size: ${(props) => props.theme.smFontSize};
    padding: ${(props) => props.theme.xsPadding};
  }
  @media ${(props) => props.theme.device.mobileXL} {
    width: 80%;
    font-size: ${(props) => props.theme.smFontSize};
    padding: ${(props) => props.theme.xsPadding};
  }
`;
