import React, { useEffect } from 'react';
import { useAppDispatch } from '../../services/reduxHooks';
import {setIsLoadingMinimized} from '../../services/reducers/loadMinimizedSlice';
import { Grid } from '@mui/material';
import { ReactComponent as Maximize } from '../../assets/Maximize.svg';
import { FixedButton } from './style';

import { useTheme } from 'styled-components';

interface Props {
  loading: boolean;
  isLoadingMinimized: boolean;
}

/**
 * Bot�o fixo no canto inferior direito.
 * Ele aparece apenas quando o usu�rio minimiza o modal de loading.
 */
const FixedLoadingButton = ({ loading, isLoadingMinimized }: Props) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(setIsLoadingMinimized(!isLoadingMinimized));
  }

  useEffect(() => {
    if(!loading && isLoadingMinimized){   // remover o bot�o se terminar o loading
      dispatch(setIsLoadingMinimized(false));
    }
  }, [loading, isLoadingMinimized]);

  return (
    <Grid display={(loading && isLoadingMinimized) ? 'flex' : 'none'}>
      <FixedButton onClick={() => handleClick()}>
        <Maximize fill={theme.fontColorMenu} style={{marginRight:'5px'}}/>
        Carregando
      </FixedButton>
    </Grid>
  );
};

export default FixedLoadingButton;