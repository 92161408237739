import * as React from 'react';
import { Box, Drawer, Toolbar, Divider, IconButton } from '@mui/material/';
import { Menu as MenuIcon } from '@mui/icons-material/';
import { MainContainer, AppBar, DrawerHeader, ButtonsWrapper, PoweredByText } from './style';
import { ReactComponent as ChevronLeftIcon } from '../../assets/Expand.svg';
import { ReactComponent as ComdinheiroLogo } from '../../assets/Comdinheiro.svg';
import { ReactComponent as Logo } from '../../assets/Logo.svg';
import { useTheme } from 'styled-components';
import useIsMobile from '../../services/useIsMobile';
import { useAppSelector } from '../../services/reduxHooks';
import BotaoUser from '../BotaoUser';

interface DrawerProps {
  drawerwidth: number;
  ListaMenu: any;
  MenuRoutes: React.ReactNode;
}

export default function PersistentDrawerLeft({ drawerwidth, ListaMenu, MenuRoutes }: DrawerProps) {
  const isMobile = useIsMobile();
  const [open, setOpen] = React.useState(!isMobile);
  const theme = useTheme();
  const [carteiraSelecionada, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.params.carteira,
    state.loadMinimized.isLoadingMinimized,
  ]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        open={open}
        drawerwidth={drawerwidth}
        style={{ background: theme.backgroundColorMain, boxShadow: theme.topMenuBoxShadow }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}>
            <MenuIcon style={{color: theme.fontColorMenu}} />
          </IconButton>
          <ButtonsWrapper>
            <BotaoUser />
          </ButtonsWrapper>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerwidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerwidth,
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {
            backgroundColor: theme.backgroundColorMain,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}>
        <>
          <DrawerHeader style={{ borderRight: theme.borderRightMenu }}>
            <Logo />
            {carteiraSelecionada !== '-1' && (
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon stroke={theme.fontColorMenu} />
              </IconButton>
            )}
          </DrawerHeader>
          <Divider
            sx={{ bgcolor: theme.backgroundColorMenuDivider, borderRight: theme.borderRightMenu }}
          />
          <ListaMenu
            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                if(isLoadingMinimized){   // n�o permitir utilizar bot�o quando minizar o modal de loading
                  e.preventDefault();
                }else{
                  (isMobile ? setOpen(false) : null)
                }
              }
            }
          />
          <div
            style={{
              display: 'flex',
              height: '100%',
              flexDirection: 'column-reverse',
              borderRight: theme.borderRightMenu,
            }}>
            <div style={{ display: 'flex' }}>
              <ComdinheiroLogo style={{ margin: 12 }} />
              <PoweredByText>Powered by Comdinheiro</PoweredByText>
            </div>
            <Divider
              sx={{ bgcolor: theme.backgroundColorMenuDivider, borderRight: theme.borderRightMenu }}
            />
          </div>
        </>
      </Drawer>
      <MainContainer open={open} drawerwidth={drawerwidth}>
        <>
          <DrawerHeader />
          {MenuRoutes}
        </>
      </MainContainer>
    </Box>
  );
}
