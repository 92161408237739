import React from 'react';
import { TextField } from '@mui/material';
import { useTheme } from 'styled-components';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dates, DatesWrapper } from './style';
import { useIsDisabled } from '../helper'

interface Props {
  dataInicial: string;
  dataFinal: string;
  setDataInicial: (value: string) => void;
  setDataFinal: (value: string) => void;
}

export default function CalendarioField({
  dataInicial,
  dataFinal,
  setDataInicial,
  setDataFinal,
}: Props) {
  const theme = useTheme();
  const dates = ['Data Inicial', 'Data Final'];

  const isDisabledIni = useIsDisabled({type: 'ini', calendarioUnico: false, datas: {data_ini: dataInicial, data_fim: dataFinal}});
  const isDisabledFim = useIsDisabled({type: 'fim', calendarioUnico: false, datas: {data_ini: dataInicial, data_fim: dataFinal}});

  return (
    <DatesWrapper>
      {dates.map((item) => (
        <Dates key={item}>
          {item}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
              value={dayjs(
                (item === 'Data Inicial' ? dataInicial : dataFinal).replace(
                  /(\d{2})(\d{2})(\d{4})/,
                  '$3-$2-$1',
                ),
              )}
              onChange={(newValue) => {
                if (item === 'Data Inicial') {
                  setDataInicial(newValue?.format('DDMMYYYY') ?? '');
                } else {
                  setDataFinal(newValue?.format('DDMMYYYY') ?? '');
                }
              }}
              dayOfWeekFormatter={(day) => {
                switch (day) {
                  case '2Âª':
                  case '6Âª':
                    day = 'S';
                    break;
                  case '4Âª':
                  case '5Âª':
                    day = 'Q';
                    break;
                  case '3Âª':
                    day = 'T';
                    break;
                }
                return day.slice(0, 1);
              }}
              inputFormat="DD/MM/YYYY"
              label={'Selecione a data'}
              shouldDisableDate={item === 'Data Inicial' ? isDisabledIni : isDisabledFim}
              renderInput={(params) => (
                <TextField
                  placeholder="dd/mm/yyyy"
                  size="small"
                  sx={{
                    marginTop: 2,
                    width: 180,
                    [`@media ${theme.device.mobileXL}`]: {
                      width: 140,
                    },
                    [`@media ${theme.device.mobileM}`]: {
                      width: 120,
                    },
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Dates>
      ))}
    </DatesWrapper>
  );
}
