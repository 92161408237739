import React, { useState } from 'react';
import { ReactComponent as Coins } from '../../assets/coins.svg';
import { Container } from '../Container';
import { MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useTheme } from 'styled-components';
import { useAppSelector } from '../../services/reduxHooks';

interface IPropsInstituicao {
  options: string[];
  initialOption: string;
  loadingOptions: boolean;
  handleSelect: (value: string) => void;
  floatLeft?: boolean;
}

export default function Instituicao({
  options,
  initialOption,
  loadingOptions,
  handleSelect,
  floatLeft
}: IPropsInstituicao) {
  const theme = useTheme();
  const [option, setOption] = useState<string>(initialOption);

  const [isLoadingMinimized] = useAppSelector((state) => [
    state.loadMinimized.isLoadingMinimized,
  ]);

  function MenuItemStyles() {
    return {
      backgroundColor: theme.dropSelectorColor,
      color: theme.backgroundColorSecondary,
      fontFamily: 'Open-sans',
      marginTop: -8,
      marginBottom: -8,
    };
  }

  const handleChange = (event: SelectChangeEvent<typeof option>) => {
    const {
      target: { value },
    } = event;
    setOption(value);
    handleSelect(value);
  };

  return (
    <Container floatLeft={floatLeft ?? false}>
      <FormControl variant="standard" style={{ display: 'flex', flexDirection: 'row' }}>
        <Select
          disabled={isLoadingMinimized}
          displayEmpty
          value={option}
          onChange={handleChange}
          renderValue={(selected) => {
            if (!selected) {
              return (
                <div style={{ color: theme.fontColor }}>
                  {(loadingOptions && !isLoadingMinimized) ? 'Carregando...' : 'Instituição'}
                </div>
              );
            }
            return options.filter((obj) => obj === selected)[0];
          }}
          inputProps={{
            'aria-label': 'Without label',
            IconComponent: () => <Coins width="25px" fill={isLoadingMinimized ? theme.disabledColor : theme.iconsColor} />,
          }}>
          {options.map((item) => (
            <MenuItem style={MenuItemStyles()} key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
}
