import styled from 'styled-components';

export const LineSmallStyle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.titleFontColor};
  width: 100%;
  border: solid 1px;
  position: absolute;
  border: solid 0.5px;
  bottom: -5px;
`;

export const LineStyle = styled.span`
  display: flex;
  color: ${({ theme }) => theme.lineColor};
  width: 100%;
  margin-top: 10px;
  border: solid 1px;
`;
