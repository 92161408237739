import React from 'react';
import { useAppDispatch } from '../../../services/reduxHooks';
import {setIsLoadingMinimized} from '../../../services/reducers/loadMinimizedSlice';
import { CircularProgress, Grid } from '@mui/material';
import { Modal, Button } from '@mui/material';
import { LoadingModalContainer } from './style';
import { Box } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinimize } from '@fortawesome/free-solid-svg-icons';

interface Props {
  loading: boolean;
  showMinimizar?: boolean;
}

const LoadingModal = ({ loading, showMinimizar = true }: Props) => {
  const dispatch = useAppDispatch();
	const handleClick = () => {   //minimizar o modal
		dispatch(setIsLoadingMinimized(true));
	}

  return (
    <Modal open={loading}>
      <LoadingModalContainer>
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          </Grid>
          <Grid item xs={12} marginTop={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              Carregando
            </Box>
          </Grid>
          <Grid item xs={12} marginTop={1}  display={showMinimizar ? 'grid' : 'none'}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Button onClick={() => { handleClick() }} style={{textTransform: 'none'}}>
                Minimizar
                <FontAwesomeIcon
                  icon={faMinimize as IconProp}
                  style={{ marginLeft: 10 }}
                  size="xs"
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </LoadingModalContainer>
    </Modal>
  );
};

export default LoadingModal;
