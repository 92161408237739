import styled from 'styled-components';
import { Button } from '@mui/material/';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;
`;

export const StyledButton = styled(Button)`
  cursor: pointer;
  padding: 8px 16px;
  margin-right: 10px;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.dropSelectorColor} !important;
  color: ${({ theme }) => theme.backgroundColorSecondary} !important;
  filter: ${({ disabled }) => (disabled ? 'brightness(0.8)' : '')};
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
  }
  @media ${(props) => props.theme.device.mobileM} {
    font-size: ${(props) => props.theme.xsFontSize};
  }
`;
