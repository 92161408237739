import { useAppDispatch } from './reduxHooks';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { setIF } from './reducers/fluxoCaixaSlice';
import { setSelectedBenchmarks as setSelectedBenchmarksResumoCart } from './reducers/resumoCartSlice';
import { setSelectedBenchmarks as setSelectedBenchmarksPerfHist } from './reducers/perfHistSlice';

/**
 * Gerenciar o historico de navega��o dos benchmarks(resumo carteira e performance historica) e do IF(fluxo de caixa)
 * Para isso, � utilizado o state do react router, guardando o estado de navegacao na history stack, pushState() do JS
 */
const useHistory = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const path = location.pathname.substring(1);
  const pathRef = useRef(path);

  const IF_history = useRef('');
  const bench_resumo_cart_history= useRef([]);
  const bench_perf_hist_history = useRef([]);

  /**
   * Mantem o path atualizado na hora de executar o evento popstate
   */
  useEffect(() => {
    pathRef.current = path;
  }, [path]);

  /**
   * Atualiza os valores do IF e benchmarks conforme o state for alterado
   */
  useEffect(() => {
    IF_history.current = location?.state?.IF ?? '';
  }, [location?.state?.IF]);
  useEffect(() => {
    bench_resumo_cart_history.current = location?.state?.bench_resumo_cart ?? null;
  }, [location?.state?.bench_resumo_cart]);
  useEffect(() => {
    bench_perf_hist_history.current = location?.state?.bench_perf_hist ?? null;
  }, [location?.state?.bench_perf_hist]);

  /**
   * Seta os benchmarks / IF quando o usu�rio clica no bot�o back/forward do navegador
   */
  const handlePopstate = () => {
    if (pathRef.current === 'fluxo-de-caixa'){  // Navega��o para a p�gina fluxo de caixa
      dispatch(setIF(IF_history.current));
    } else if(pathRef.current === 'resumo-da-carteira'){  // Navega��o para a p�gina Resumo Carteira
      if(bench_resumo_cart_history.current){
        dispatch(setSelectedBenchmarksResumoCart(bench_resumo_cart_history.current));
      }
    } else if(pathRef.current === 'performance-historica'){  // Navega��o para a p�gina Performance Historica
      if(bench_perf_hist_history.current){
        dispatch(setSelectedBenchmarksPerfHist(bench_perf_hist_history.current));
      }
    }
  }

  /**
   * Executa ao clicar no bot�o back/forward do navegador
   */
  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);
    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
};

export default useHistory;