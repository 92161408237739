import React, { useEffect, useState, PropsWithChildren } from 'react';
import { Modal } from '@mui/material';
import { StyledModalContainer } from './style';
import { useAppSelector } from '../../services/reduxHooks';

interface Props {
  onCloseHandle: ((value: any) => void | null) | ((data: any) => Promise<void>);
  preventClosing?: boolean;
  open: boolean;
  minWidth?: number;
  maxWidth?: number;
  width?: string;
}

export const CustomModal = ({
  open,
  onCloseHandle,
  preventClosing,
  minWidth,
  maxWidth,
  width,
  children,
}: PropsWithChildren<Props>) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = (event: any, reason: string) => {
    if (!preventClosing) {
      setModalOpen(false);
      onCloseHandle(null);
    }
  }

  useEffect(() => {
    setModalOpen(open);
  }, [open]);

  return (
    <Modal
      open={modalOpen}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description">
      <StyledModalContainer width={width} minWidth={minWidth} maxWidth={maxWidth}>
        {children}
      </StyledModalContainer>
    </Modal>
  );
};
