import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Container } from '../Container';
import { useTheme } from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import { setDataFim } from '../../services/reducers/preferenceSlice';
import { Dates } from './Calendario/style';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt-br';
import { useIsDisabled } from './helper'

export default function Periodo() {
  const dispatch = useAppDispatch();
  const [dataInicialCarteira, dataFinalCarteira, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.dateStart,
    state.preference.dateEnd,
    state.loadMinimized.isLoadingMinimized,
  ]);
  const theme = useTheme();
  const [dataFinal, setDataFinal] = useState<string>(dataFinalCarteira);
  const isDisabled = useIsDisabled();

  return (
    <Container>
      <Dates>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
              disabled={isLoadingMinimized}
              value={dayjs(
                dataFinal.replace(
                  /(\d{2})(\d{2})(\d{4})/,
                  '$3-$2-$1',
                ),
              )}
              onChange={(newValue) => {
                if(newValue && newValue.format('DDMMYYYY') !== 'Invalid Date' && !isDisabled(newValue)){
                  setDataFinal(newValue?.format('DDMMYYYY') ?? '');
                } else {
                  setDataFinal('');
                }

              }}
              onAccept={newValue => {
                setDataFinal(newValue?.format('DDMMYYYY') ?? '');
                dispatch(setDataFim(newValue?.format('DDMMYYYY') ?? ''))
              }}
              dayOfWeekFormatter={(day) => {
                switch (day) {
                  case '2�':
                  case '6�':
                    day = 'S';
                    break;
                  case '4�':
                  case '5�':
                    day = 'Q';
                    break;
                  case '3�':
                    day = 'T';
                    break;
                }
                return day.slice(0, 1);
              }}
              inputFormat="DD/MM/YYYY"
              label={'Selecione a data da consulta'}
              shouldDisableDate={isDisabled}
              renderInput={(params) => (
                <TextField
                  placeholder="dd/mm/yyyy"
                  size="small"
                  sx={{
                    marginTop: 2,
                    width: 180,
                    [`@media ${theme.device.mobileXL}`]: {
                      width: 140,
                    },
                    [`@media ${theme.device.mobileM}`]: {
                      width: 120,
                    },
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && dataFinal !== '') {
                      dispatch(setDataFim(dataFinal))
                    }
                  }}
                  {...params}
                />
              )}
            />
          </LocalizationProvider>
        </Dates>
    </Container>
  );
}
