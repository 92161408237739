import { checkPeriodoPersonalizado } from '../helper';
import { AxiosError } from 'axios';
import { IEstatsState } from '../reducers/estatsSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockEstats from '../mock/estats.json';
import api from './index';

interface IEstatsAPI {
  EstatAvanc: IEstatsState;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchEstats = createAsyncThunk<IEstatsAPI, void, { state: RootState }>(
  'estats/fetch',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockEstats;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: perdiodoPersonalizado
          ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
          : periodo,
        // classe: 'MV(estrategia) TIPO',
        // subclasse: 'MV(risco)',
        abas: ['EstatAvanc'],
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${process.env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
