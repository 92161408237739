import React, { useEffect, useState, useRef } from 'react';
import { ReactComponent as Calendar } from '../../assets/Calendar.svg';
import { Container } from '../Container';
import { MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import { useTheme } from 'styled-components';
import ModalPersonalizado from './ModalPersonalizado';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import { setData } from '../../services/reducers/preferenceSlice';
import { checkPeriodoPersonalizado } from '../../services/helper';

export default function Periodo() {
  const dispatch = useAppDispatch();
  const [periodo, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.params.periodo,
    state.loadMinimized.isLoadingMinimized,
  ]);
  const theme = useTheme();
  const initialOption = checkPeriodoPersonalizado(periodo ?? '') ? 'personalizado' : periodo;
  const [option, setOption] = useState<string>(initialOption);
  const [openModal, setOpenModal] = useState(false);

  const [containerHeight, setContainerHeight] = useState<any>(162);
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref && ref.current && ref.current.offsetTop) {
      setContainerHeight(ref.current.offsetTop);
    }
  });

  function MenuItemStyles() {
    return {
      backgroundColor: theme.dropSelectorColor,
      color: theme.backgroundColorSecondary,
      fontFamily: 'Open-sans',
      marginTop: -8,
      marginBottom: -8,
    };
  }

  const menuItemOptions = [
    { label: 'Desde o início', value: 'maximo' },
    { label: 'No mês', value: 'mes_atual' },
    { label: '1 ano', value: '12m' },
    { label: '2 anos', value: '24m' },
    { label: '3 anos', value: '36m' },
    { label: 'Personalizado', value: 'personalizado' },
  ];

  const handleChange = (event: SelectChangeEvent<typeof option>) => {
    const {
      target: { value },
    } = event;
    setOption(value);
    if (value !== 'personalizado') {
      dispatch(setData(value));
    }
  };

  const handleSelectPersonalizado = () => {
    setOpenModal(true);
  };

  return (
    <Container>
      <FormControl ref={ref} variant="standard" style={{ display: 'flex', flexDirection: 'row' }}>
        <Select
          style={{ color: theme.iconsColor}}
          disabled={isLoadingMinimized}
          displayEmpty
          value={option}
          onChange={handleChange}
          renderValue={(selected) => {
            const select =
              menuItemOptions.filter((obj) => obj.value === selected)[0]?.label ?? false;
            if (!select) {
              return <div style={{ color: theme.fontColor }}>Período</div>;
            }
            return select;
          }}
          inputProps={{
            'aria-label': 'Without label',
            IconComponent: () => <Calendar width="25px" fill={isLoadingMinimized ? theme.disabledColor : theme.iconsColor} />,
          }}>
          {menuItemOptions.map((item) => (
            <MenuItem
              style={MenuItemStyles()}
              key={item.value}
              value={item.value}
              onClick={item.value === 'personalizado' ? handleSelectPersonalizado : () => null}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ModalPersonalizado
        openModal={openModal}
        setOpenModal={setOpenModal}
        containerHeight={containerHeight}
      />
    </Container>
  );
}
