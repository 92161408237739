import styled from 'styled-components';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

interface IDivProps {
  open: boolean;
  drawerwidth: number;
}

export const MainContainer = styled.div<IDivProps>`
  transition: none !important;
  flex-grow: 1;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  transition: margin
    ${(props) => {
      return `${props.theme.transitions.duration.leavingScreen} ${props.theme.transitions.easing.sharp}`;
    }} !important;
  margin-left: ${(props) => (props.open ? '0' : `-${props.drawerwidth}px`)};

  @media ${(props) => props.theme.device.tablet} {
    margin-left: ${(props) => `-${props.drawerwidth}px`} !important;
    padding: 4px;
  }

  ${(props) =>
    props.open &&
    `transition: margin ${props.theme.transitions.duration.enteringScreen} ${props.theme.transitions.easing.easeOut} !important;
    width: calc(100% - ${props.drawerwidth}px) !important;`}
`;

interface AppBarProps extends MuiAppBarProps {
  open: boolean;
  drawerwidth: number;
}

/* export const AppBar = styled.div<AppBarProps>` */
export const AppBar = styled(MuiAppBar)<AppBarProps>`
  transition: none !important;
  transition-property: margin, width !important;
  transition-duration: ${(props) => props.theme.transitions.duration.leavingScreen} !important;
  transition-timing-function: ${(props) => props.theme.transitions.easing.sharp} !important;
  ${(props) =>
    props.open &&
    `
      transition-duration: ${props.theme.transitions.duration.enteringScreen} !important;
      transition-timing-function:${props.theme.transitions.easing.easeOut} !important;
      `}
  margin-left: ${(props) => (props.open ? `${props.drawerwidth}px` : '0px')};
`;

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
  justify-content: space-between;
  min-height: 56px;
  @media (min-width: 600px) {
    min-height: 64px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const PoweredByText = styled.div`
  color: ${({ theme }) => theme.fontColorMenu};
  font-size: ${({ theme }) => theme.smFontSize};
  align-self: center;
`;
