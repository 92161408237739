import { useState, useEffect } from 'react';
import {ICarteira} from '../../services/reducers/preferenceSlice';

/**
 * Obter o Prefixo Monet�rio da carteira selecionada
 * @param carteiraList Lista das carteiras do usu�rio
 * @param carteira Nome da carteira
 * @returns Prefixo monet�rio da carteira
 */
const MoneyPrefix = (carteiraList: Array<ICarteira>, carteira: string | null) => {
    const [moneyPrefix, setMoneyPrefix] = useState<string | null>(null);
    useEffect(() => {
        if (carteiraList && carteiraList[0] && carteira){
            const carteira_result = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase());
            if(carteira_result[0]){
                setMoneyPrefix(carteira_result[0].moeda);
            }
        }
    }, [carteiraList, carteira]);
    return moneyPrefix;
}

export default MoneyPrefix