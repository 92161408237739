import styled from 'styled-components';

export const FixedButton = styled.button`
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.backgroundColorMain};
  color: ${({ theme }) => theme.fontColorMenu};
  border: none;
  border-radius: 7px;
  cursor: pointer;
  z-index: 10;
`;