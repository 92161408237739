import React from 'react'
import { Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';

import {
  StyledLogo,
  CenteredSquare,
  Form,
  LoginContainer,
  LogoContainer,
  LoginTitleContainer,
  EsqueciMinhaSenhaContent,
  LoginEsqueciLink,
} from './style';

export default function EsqueciSenha() {
  return (
    <LoginContainer>
      <CenteredSquare>
        <LogoContainer>
          <StyledLogo />
        </LogoContainer>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LoginTitleContainer>Recuperação de Senha</LoginTitleContainer>
            </Grid>
            <Grid item xs={12}>
              <EsqueciMinhaSenhaContent>
                Para redefinir sua senha, entre em contato com seu gestor.
              </EsqueciMinhaSenhaContent>
            </Grid>
            <Grid item xs={12}>
              <LoginEsqueciLink to="/login">
                <FontAwesomeIcon icon={faCircleArrowLeft as IconProp} style={{ marginRight: 12 }} />
                Voltar
              </LoginEsqueciLink>
            </Grid>
          </Grid>
        </Form>
      </CenteredSquare>
    </LoginContainer>
  )
}
