import styled from 'styled-components';
import Box from '@mui/material/Box';

export const LoadingModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 200px;
  border-radius: 12px;
  padding: ${({ theme }) => theme.mdPadding};
  outline: none;
`;
