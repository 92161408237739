import React from 'react';
import { Card, CardContent, CardsWrapper, CardTitle, StyledSkeleton, CardsWrapperPDF, CardPDF, CardContentPDF, CardTitlePDF} from './style';
import { Grid } from '@mui/material';

interface Props {
  cards:
    | {
        title: string;
        value: string | undefined;
      }[]
    | null;
}

export function Cards({ cards }: Props) {
  const arr = Array(5).fill(true);
  return cards && cards.length > 0 ? (
    <CardsWrapper>
      {cards.map((item) => (
        <Grid item xs={1} key={item.title}>
          <Card key={item.title}>
            <CardTitle>{item.title}</CardTitle>
            <CardContent>{item.value}</CardContent>
          </Card>
        </Grid>
      ))}
    </CardsWrapper>
  ) : (
    <CardsWrapper>
      {arr.map((item, i) => (
        <Grid item xs={1} key={i}>
          <StyledSkeleton />
        </Grid>
      ))}
    </CardsWrapper>
  );
}

export function CardsPDF({ cards }: Props) {
  const arr = Array(5).fill(true);
  return cards && cards.length > 0 ? (
    <CardsWrapperPDF>
      {cards.map((item) => (
        <Grid item xs={1} key={item.title}>
          <CardPDF key={item.title}>
            <CardTitlePDF>{item.title}</CardTitlePDF>
            <CardContentPDF>{item.value}</CardContentPDF>
          </CardPDF>
        </Grid>
      ))}
    </CardsWrapperPDF>
  ) : (
    <CardsWrapperPDF>
      {arr.map((item, i) => (
        <Grid item xs={1} key={i}>
          <StyledSkeleton />
        </Grid>
      ))}
    </CardsWrapperPDF>
  );
}
