import axios from 'axios';
import { signal } from './abortAPI';

const api = (apiKey?: string | null, contentType?: string) => {
  const headers: { [key: string]: string } = {
    'Content-Type': contentType ?? 'application/json',
  };
  if (apiKey) headers.Authorization = `Bearer ${apiKey}`;

  const axiosVar = axios.create({
    method: 'post',
    baseURL: `${
      window.location.hostname === 'localhost'
        ? 'https://www.comdinheiro.com.br'
        : 'https://www.comdinheiro.com.br'
    }/Clientes/`,
    headers,
    signal,
  });

  function isValidJson(response: any) {
    if (typeof response === 'object' && response !== null) {
      try {
        JSON.stringify(response);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  axiosVar.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  axiosVar.interceptors.response.use(
    function (response) {
      if (!isValidJson(response.data)) {
        window.location.replace('/');
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosVar;
};

export default api;
