import React from 'react';
import { useNavigate } from 'react-router-dom';
import { MenuItem } from '@mui/material/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSignOut } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';
import {
  resetParams,
} from '../../services/reducers/preferenceSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksResumoCart} from '../../services/reducers/resumoCartSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksPerfHist} from '../../services/reducers/perfHistSlice';
import {cancelarAPI} from '../../services/api/abortAPI';

const BotaoLogout = (setAnchorEl?: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoadingMinimized] = useAppSelector((state) => [
    state.loadMinimized.isLoadingMinimized,
  ]);

  // botão logout
  const handleLogout = () => {
    window.localStorage.removeItem(`token-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`);
    window.localStorage.removeItem(
      `carteiraFavorita-${process.env.REACT_APP_NAME ?? 'comdinheiro'}`,
    );
    dispatch(resetParams());
    // resetar os benchmarks
    dispatch(resetSelectedBenchmarksResumoCart());
    dispatch(resetSelectedBenchmarksPerfHist());
    if(isLoadingMinimized){   // cancelar todas as chamadas de API
      cancelarAPI();
    }
    navigate('/login');
    if(typeof setAnchorEl !== 'undefined') {
      setAnchorEl = (e: any) => {return;};
    }
    setAnchorEl(null);
  };

  return (
    <MenuItem onClick={handleLogout}>
      <FontAwesomeIcon icon={faSignOut as IconProp} style={{ marginRight: 8 }} />
      Logout
    </MenuItem>
  )
}

export default BotaoLogout;
