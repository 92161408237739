import React from 'react';
import useIsMobile from '../../services/useIsMobile';
import { BotaoContainer, HeaderContainerStyle } from './style';

interface Props {
  children?: React.ReactNode;
  floatLeft?: boolean;
}

export function Container(props: Props) {
  return <BotaoContainer floatLeft={props.floatLeft ?? false}>{props.children}</BotaoContainer>;
}

export function HeaderContainer(props: Props) {
  const isMobile = useIsMobile();
  let isBreadcrumb = false;

  if (props.children && props.children[0 as keyof typeof props.children]['props']['screen']) {
    isBreadcrumb = true;
  }
  return (
    <HeaderContainerStyle isMobile={isMobile && !isBreadcrumb}>
      {props.children}
    </HeaderContainerStyle>
  );
}
