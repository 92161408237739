import React from 'react';
import { Modal } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Container, Header } from './style';
import Calendario from '../../../pages/MainComponent/Calendario';

interface ModalProps {
  openModal: boolean;
  setOpenModal: (value: boolean) => void;
  containerHeight: number;
}

export default function ModalPersonalizado({
  openModal,
  setOpenModal,
  containerHeight,
}: ModalProps) {
  const theme = useTheme();
  return (
    <Modal open={openModal} onClose={() => setOpenModal(false)}>
      <Container containerHeight={containerHeight}>
        <Header>
          Período personalizado
          <Close
            onClick={() => setOpenModal(false)}
            sx={{
              cursor: 'pointer',
              width: 20,
              [`@media ${theme.device.mobileXL}`]: {
                width: 15,
              },
              [`@media ${theme.device.mobileM}`]: {
                width: 10,
              },
            }}
          />
        </Header>
        <Calendario setOpenModal={setOpenModal} />
      </Container>
    </Modal>
  );
}
