import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './services/store';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import App from './App'; // import reportWebVitals from './reportWebVitals';
import { lightTheme } from './style/theme';
import './index.css';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Open Sans';
    margin: 0;
    padding: 0;
    background-color: ${(props) => props.theme.backgroundColor};
    font-size: ${(props) => props.theme.mdFontSize};

    @media ${(props) => props.theme.device.mobileXL} {
      font-size: ${(props) => props.theme.smFontSize};
    }
  }
`;

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={lightTheme}>
      <GlobalStyle />
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
);
