import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IloadMinimized {
  isLoadingMinimized: boolean;
}

const initialState: IloadMinimized = {
  isLoadingMinimized: false,
};

export const loadMinimizedSlice = createSlice({
  name: 'loadMinimized',
  initialState,
  reducers: {
    setIsLoadingMinimized: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMinimized = action.payload;
    },
  },
});

export const {
  setIsLoadingMinimized,
} = loadMinimizedSlice.actions;

export default loadMinimizedSlice.reducer;