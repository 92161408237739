import React from 'react';
import EsqueciSenha from '../../components/Login/EsqueciSenha';

function EsqueciMinhaSenha() {
  return (
    <EsqueciSenha />
  );
}

export default EsqueciMinhaSenha;
