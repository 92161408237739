import { Grid } from '@mui/material';
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import LoadingModal from '../LoadingModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import MoneyPrefix from '../../../components/MoneyPrefix';
import {
  helperFormat,
  FormatTypes,
  limitToModulus,
  auxFormatPercent,
  groupAndSumByProperties,
  sumByProperties,
  hexToRGBA,
} from '../../../services/helper';
import { useTheme } from 'styled-components';

import ResumoCarteira from '../ResumoCarteira';
import PerformanceHistorica from '../PerformanceHistorica';
import PosicaoConsolidada from '../PosicaoConsolidada';
// import CarteiraExplodida from '../CarteiraExplodida';
import FluxoAtivos from '../FluxoAtivos';
import FluxoCaixa from '../FluxoCaixa';
import EstatisticasAvancadas from '../EstatisticasAvancadas';
import { CardsPDF } from '../../../components/Cards';
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { fetchModalPosCon } from '../../../services/api/posConAPI';
import { chartColors } from '../../../style/vars';
import CapaPDF from '../../../assets/CapaPDF.jpg';
import LogoPDF from '../../../assets/LogoPDF.jpg';
import './style.css';

import GraficoLinha from '../../../components/GraficoLinha';
import GraficoArea from '../../../components/GraficoArea';
import { GraficoBarra } from '../../../components/GraficoBarra';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import * as htmlToImage from 'html-to-image';
import 'svg2pdf.js'
import '../../../assets/fonts/OpenSans-Regular-normal'
import '../../../assets/fonts/OpenSans-Bold-bold'

export default function GerarPDFTodos(props: any) {

  const dispatch = useAppDispatch();

  const [
    params,
    retorno,
    resumo,
    isLoadedResumoCart,
    rendPorClasse,
    dadosPercentResumo,
    tabela_liquidez,
    movimentacoes,
    alocCustodiante,
    isPercentToggle,
    infos_add,
    obj_datas,
    retorno_carteira_bench,
    vol_carteira_bench,
    sharpe_carteira_bench,
    isLoadedEstats,
    fluxo_de_ativos,
    isLoadedFluxoAtivos,
    dadosMesaMes,
    movimentacoesPH,
    isLoadedPerfHist,
    dados_ativos_agrupados,
    datas_dados_ativos_agrupados,
    retornos_ativos,
    carteiraList,
    carteira,
    isLoadedPosCon,
    graficoRetornos,
    graficoPL,
    rendimento12meses,
    isLoadedCartExp,
    // dados_cart_explodida,
  ] = useAppSelector((state) => [
    state.preference.params,
    state.resumoCart.retorno,
    state.resumoCart.resumo,
    state.resumoCart.isLoaded,
    state.resumoCart.rendPorClasse,
    state.resumoCart.dadosPercentResumo,
    state.resumoCart.tabela_liquidez,
    state.resumoCart.movimentacoes,
    state.resumoCart.alocCustodiante,
    state.resumoCart.isPercentToggle,
    state.estats.infos_add,
    state.estats.obj_datas,
    state.estats.retorno_carteira_bench,
    state.estats.vol_carteira_bench,
    state.estats.sharpe_carteira_bench,
    state.estats.isLoaded,
    state.fluxoAtivos.fluxo_de_ativos,
    state.fluxoAtivos.isLoaded,
    state.perfHist.dadosMesaMes,
    state.perfHist.movimentacoes,
    state.perfHist.isLoaded,
    state.posCon.dados_ativos_agrupados,
    state.posCon.datas_dados_ativos_agrupados,
    state.posCon.retornos_ativos,
    state.preference.carteiraList,
    state.preference.params.carteira,
    state.posCon.isLoaded,
    state.perfHist.graficoRetornos,
    state.perfHist.graficoPL,
    state.perfHist.rendimento12meses,
    state.cartExp.isLoaded,
    // state.cartExp.dados_cart_explodida,
  ]);

  const isLoaded =
    isLoadedResumoCart &&
    isLoadedEstats &&
    isLoadedFluxoAtivos &&
    isLoadedPerfHist &&
    isLoadedPosCon
    // isLoadedPosCon &&
    // isLoadedCartExp;

  const useFormat = (value: any) =>
    helperFormat(value, isPercentToggle ? FormatTypes.percentage : FormatTypes.monetary, 2);
  type formatType = FormatTypes.percentage | FormatTypes.monetary;

  const theme = useTheme();
  const { state, search } = useLocation();

  const rowsRetorno =
    isLoaded && retorno
      ? retorno.map((ret) => {
        let formatType: formatType = FormatTypes.percentage;
        if (ret.descricao === 'Rend. Nominal') formatType = FormatTypes.monetary;
        return {
          ativo: ret.descricao,
          mes: helperFormat(ret.mes, formatType, 2),
          ano: helperFormat(ret.ano, formatType, 2),
          mes12: helperFormat(ret.m12, formatType, 2),
          mes24: helperFormat(ret.m24, formatType, 2),
          periodo: helperFormat(ret.periodo, formatType, 2),
        };
      })
      : null;

  const columnsRetorno: ITableColumns[] = [
    {
      id: 'ativo',
      label: 'Ativo',
      freeze: true,
    },
    {
      id: 'mes',
      label: 'Mês',
    },
    {
      id: 'ano',
      label: 'Ano',
    },
    {
      id: 'mes12',
      label: '12 meses',
    },
    {
      id: 'mes24',
      label: '24 meses',
    },
  ];

  if (params.periodo !== '12m' && params.periodo !== '24m' && params.periodo !== 'mes_atual')
    columnsRetorno.push({
      id: 'periodo',
      label: params.periodo === 'maximo' ? 'Desde o início' : 'Período',
    });

  const rowsClasse =
    isLoaded && rendPorClasse
      ? rendPorClasse.map((classe, ind) => {
        const colorIndex =
          classe.classe !== 'Caixa Bloqueado' &&
            classe.classe !== 'Total da Carteira' &&
            classe.classe !== 'Total Disponível' &&
            classe.classe !== 'Total'
            ? ind
            : false;

        return {
          classe: classe.classe,
          saldo: helperFormat(classe.saldo, FormatTypes.monetary),
          percent: helperFormat(Number(classe.percent) * 100, FormatTypes.percentage, 2),
          mes: isPercentToggle
            ? useFormat(dadosPercentResumo?.[ind].mes_perf)
            : useFormat(classe.mes),
          ano: isPercentToggle
            ? useFormat(dadosPercentResumo?.[ind].ano_perf)
            : useFormat(classe.ano),
          colorIndex,
          bold:
            classe.classe === 'Total Disponível' ||
            classe.classe === 'Total da Carteira' ||
            classe.classe === 'Total da Carteira' ||
            classe.classe === 'Total',
        };
      })
      : null;

  const columnsClasse: ITableColumns[] = [
    {
      id: 'classe',
      label: 'Classe',
      orderBy: 'classe',
      colorLegend: true,
      freeze: true,
    },
    {
      id: 'saldo',
      label: 'Saldo Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'percent',
      label: '%',
      align: AlignTypes.right,
    },
    {
      id: 'mes',
      label: 'No Mês',
      align: AlignTypes.right,
    },
    {
      id: 'ano',
      label: 'No Ano',
      align: AlignTypes.right,
    },
  ];

  const rowsLiq =
    isLoaded && tabela_liquidez
      ? tabela_liquidez.slice(1).map((arrayLiq) => {
        return {
          periodo: arrayLiq[0],
          valor: helperFormat(arrayLiq[1], FormatTypes.decimals),
          percent: helperFormat(Number(arrayLiq[2]) * 100, FormatTypes.decimals),
        };
      })
      : null;

  const columnsLiq: ITableColumns[] = [
    {
      id: 'periodo',
      label: 'Tempo de Resgate (DC)',
    },
    {
      id: 'valor',
      label: 'Valor Líquido',
      align: AlignTypes.right,
    },
    {
      id: 'percent',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const rowsMovim =
    isLoaded && movimentacoes
      ? movimentacoes.map((movim) => {
        return {
          mesano: movim.data,
          saldoMovim: helperFormat(movim.saldoMovim, FormatTypes.monetary),
          saldoBruto: helperFormat(movim.saldoBruto, FormatTypes.monetary),
        };
      })
      : null;

  const columnsMovim: ITableColumns[] = [
    {
      id: 'mesano',
      label: 'Mês/Ano',
    },
    {
      id: 'saldoMovim',
      label: 'Saldo Movimentações',
      align: AlignTypes.right,
    },
    {
      id: 'saldoBruto',
      label: 'Saldo Bruto',
      align: AlignTypes.right,
    },
  ];

  const rowsInfos1 =
    isLoaded && infos_add && infos_add.retorno && infos_add.pl_data_fim && infos_add.pl_medio
      ? infos_add.retorno.slice(1).map((row) => {
        let i = 0;
        return {
          info: row[i++],
          carteira: helperFormat(row[i++], FormatTypes.decimals, 2),
          bench: helperFormat(row[i++], FormatTypes.decimals, 2),
        };
      })
      : null;
  if (rowsInfos1 !== null && rowsInfos1.length > 0) {
    rowsInfos1.push({
      info: `PL em ${obj_datas.data_fim}`,
      carteira: '',
      bench: helperFormat(infos_add.pl_data_fim, FormatTypes.monetary, 2),
    });
    rowsInfos1.push({
      info: 'PL médio no período',
      carteira: '',
      bench: helperFormat(infos_add.pl_medio, FormatTypes.monetary, 2),
    });
  }

  const columnsInfos1: ITableColumns[] = [
    {
      id: 'info',
      label: '',
      align: AlignTypes.left,
    },
    {
      id: 'carteira',
      label: 'Carteira',
      align: AlignTypes.right,
    },
    {
      id: 'bench',
      label: isLoaded && infos_add && infos_add.retorno ? infos_add.retorno[0][2].toString() : '-',
      align: AlignTypes.right,
    },
  ];

  const rowsInfos2 =
    isLoaded && infos_add && infos_add.comparativo
      ? infos_add.comparativo.slice(1).map((row) => {
        let i = 0;
        return {
          info: row[i++],
          valor: row[i++],
          percent: helperFormat(row[i++], FormatTypes.percentage, 2),
        };
      })
      : null;

  const columnsInfos2: ITableColumns[] = [
    {
      id: 'info',
      label: '',
      align: AlignTypes.left,
    },
    {
      id: 'valor',
      label: 'Valor',
      align: AlignTypes.right,
    },
    {
      id: 'percent',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const rowsRetornos =
    isLoaded && retorno_carteira_bench
      ? retorno_carteira_bench.slice(1).map((row) => {
        const returnArr = [row[0], helperFormat(row[1], FormatTypes.decimals, 2)];
        row.slice(2).forEach((val, i) => {
          returnArr.push(helperFormat(val, FormatTypes.decimals, 2));
        });
        return returnArr;
      })
      : null;

  const rowsVol =
    isLoaded && vol_carteira_bench
      ? vol_carteira_bench.slice(1).map((row) => {
        const returnArr = [row[0], helperFormat(row[1], FormatTypes.decimals, 2)];
        row.slice(2).forEach((val, i) => {
          returnArr.push(helperFormat(val, FormatTypes.decimals, 2));
        });
        return returnArr;
      })
      : null;

  const rowsSharpe =
    isLoaded && sharpe_carteira_bench
      ? sharpe_carteira_bench.slice(1).map((row) => {
        const returnArr = [row[0], helperFormat(row[1], FormatTypes.decimals, 2)];
        row.slice(2).forEach((val, i) => {
          returnArr.push(helperFormat(limitToModulus(val, 999.99), FormatTypes.decimals, 2));
        });
        return returnArr;
      })
      : null;

  const headersEstats = ['Período', 'Carteira'];

  if (isLoaded && retorno_carteira_bench && retorno_carteira_bench[0]) {
    retorno_carteira_bench[0].slice(2).forEach((val) => {
      headersEstats.push(val.toString());
    });
  }

  const rowsFluxoAtivos =
    isLoaded && fluxo_de_ativos
      ? fluxo_de_ativos.slice(1).map((row) => {
        let i = 0;
        const dataAux = row[i++] as string;
        return {
          data: dataAux.split('-').reverse().join('/'),
          tipo: row[i++],
          desc: row[i++],
          op: row[i++],
          quant: helperFormat(row[i++], FormatTypes.decimals, 2),
          valBruto: helperFormat(row[i++], FormatTypes.decimals, 2),
          valLiquido: helperFormat(row[i++], FormatTypes.decimals, 2),
        };
      })
      : null;

  const columnsFluxoAtivos: ITableColumns[] = [
    {
      id: 'data',
      label: 'Data',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'tipo',
      label: 'Tipo',
      align: AlignTypes.left,
    },
    {
      id: 'desc',
      label: 'Descrição',
      align: AlignTypes.left,
    },
    {
      id: 'op',
      label: 'Operação',
      align: AlignTypes.left,
    },
    {
      id: 'quant',
      label: 'Quantidade',
      align: AlignTypes.right,
    },
    {
      id: 'valBruto',
      label: 'Valor Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'valLiquido',
      label: 'Valor Líquido',
      align: AlignTypes.right,
    },
  ];

  interface GroupedDataRetornos {
    [year: number | string]: (number | string)[][];
  }

  const dadosMesaMesAgrupados: GroupedDataRetornos = dadosMesaMes.reduce(
    (accum: GroupedDataRetornos, current: (number | string)[]) => {
      const year: number | string = current[0];

      if (!year || year === '') return accum;

      const values: (number | string)[] = current.slice(1);

      if (!accum[year]) accum[year] = [];
      accum[year].push(values);
      return accum;
    },
    {} as GroupedDataRetornos,
  );
  const moneyPrefix = MoneyPrefix(carteiraList, carteira);

  const cardsData =
    isLoaded && resumo
      ? [
        {
          title: `Saldo ${resumo?.datas.data_ini.split('-').reverse().join('/')}`,
          value: helperFormat(resumo?.saldoIni, FormatTypes.monetary, 2, moneyPrefix),
        },
        {
          title: 'Movimentações',
          value: helperFormat(resumo?.movimentacoes, FormatTypes.monetary, 2, moneyPrefix),
        },
        {
          title: 'Impostos Pagos',
          value: helperFormat(resumo?.impostospagos, FormatTypes.monetary, 2, moneyPrefix),
        },
        {
          title: 'Rendimento Bruto',
          value: helperFormat(resumo?.rendimentoBruto, FormatTypes.monetary, 2, moneyPrefix),
        },
        {
          title: `Saldo ${resumo?.datas.data_fim.split('-').reverse().join('/')}`,
          value: helperFormat(resumo?.saldoFim, FormatTypes.monetary, 2, moneyPrefix),
        },
      ]
      : null;

  const pieChartClassData =
    isLoaded && rendPorClasse
      ? rendPorClasse
        .filter(
          (obj) =>
            obj.classe !== 'Caixa Bloqueado' &&
            obj.classe !== 'Total da Carteira' &&
            obj.classe !== 'Total Disponível' &&
            obj.classe !== 'Total',
        )
        .map((obj, index) => ({
          name: obj.classe,
          value:
            typeof obj.percent === 'number'
              ? Number((obj.percent * 100).toFixed(2))
              : Number((Number(obj.percent) * 100).toFixed(2)),
          color: theme.chartColors[index % theme.chartColors.length],
        }))
        .sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0))
      : null;

  const pieChartCustodianteData =
    isLoaded && alocCustodiante
      ? alocCustodiante
        .map((obj) => ({
          name: obj.custodiante !== '' ? obj.custodiante : 'Outros',
          value:
            typeof obj.alocacao === 'number'
              ? Number((obj.alocacao * 100).toFixed(2))
              : Number((Number(obj.alocacao) * 100).toFixed(2)),
        }))
        .sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0))
      : null;

  const columnsRetornosPH: ITableColumns[] = [
    {
      id: 'ano',
      label: '',
      rowSpan:
        Object.keys(dadosMesaMesAgrupados).length <= 0
          ? 0
          : dadosMesaMesAgrupados[Object.keys(dadosMesaMesAgrupados)[0]].length,
      bgColor: theme.titleFontColor,
      color: 'white',
      padding: '4px',
      align: AlignTypes.center,
      borderLeftRadius: '5px',
      freeze: true,
    },
    {
      id: 'ativo',
      label: 'Ativo',
      freeze: true,
      leftOnFreeze: 40,
      noBreak: true,
    },
    { id: 'Jan', label: 'Jan', align: AlignTypes.right },
    { id: 'Fev', label: 'Fev', align: AlignTypes.right },
    { id: 'Mar', label: 'Mar', align: AlignTypes.right },
    { id: 'Abr', label: 'Abr', align: AlignTypes.right },
    { id: 'Mai', label: 'Mai', align: AlignTypes.right },
    { id: 'Jun', label: 'Jun', align: AlignTypes.right },
    { id: 'Jul', label: 'Jul', align: AlignTypes.right },
    { id: 'Ago', label: 'Ago', align: AlignTypes.right },
    { id: 'Set', label: 'Set', align: AlignTypes.right },
    { id: 'Out', label: 'Out', align: AlignTypes.right },
    { id: 'Nov', label: 'Nov', align: AlignTypes.right },
    { id: 'Dez', label: 'Dez', align: AlignTypes.right },
    { id: 'Ano', label: 'Ano', align: AlignTypes.right },
    { id: 'Acum', label: 'Acum', align: AlignTypes.right },
  ];

  const rowsRetornosPH =
    isLoaded && dadosMesaMesAgrupados
      ? Object.entries(dadosMesaMesAgrupados).flatMap(([year, data]) =>
        data.map((subArray, index) => {
          let i = 0;
          const rowData: { [key: string]: string | number } = {
            ativo: subArray[i++],
            Jan: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Fev: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Mar: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Abr: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Mai: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Jun: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Jul: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Ago: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Set: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Out: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Nov: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Dez: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Ano: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
            Acum: helperFormat(auxFormatPercent(subArray[i++]), FormatTypes.decimals, 2),
          };
          if (index === 0) rowData.ano = year;

          return rowData;
        }),
      )
      : null;

  const columnsMovimPH: ITableColumns[] = [
    { id: 'mesano', label: 'Mês/Ano', freeze: true },
    { id: 'saldoIni', label: 'Saldo Inicial (A)', align: AlignTypes.right },
    { id: 'aplicacoes', label: 'Aplicações (B)', align: AlignTypes.right },
    { id: 'resgates', label: 'Resgates (C)', align: AlignTypes.right },
    { id: 'impostos', label: 'Impostos Pagos (D)', align: AlignTypes.right },
    { id: 'saldoMovim', label: 'Saldo Movimentações (E=B-C)', align: AlignTypes.right },
    { id: 'rendNominal', label: 'Rendimento Nominal (F)', align: AlignTypes.right },
    { id: 'saldoFim', label: 'Saldo Final (G=A-D+E+F)', align: AlignTypes.right },
  ];

  const rowsMovimPH =
    isLoaded && movimentacoesPH
      ? movimentacoesPH.map((movim) => {
        return {
          mesano: movim.data,
          saldoIni: helperFormat(movim.saldoBrutoIni, FormatTypes.decimals),
          aplicacoes: helperFormat(movim.apl, FormatTypes.decimals),
          resgates: helperFormat(movim.res, FormatTypes.decimals),
          impostos: helperFormat(movim.imp, FormatTypes.decimals),
          saldoMovim: helperFormat(movim.saldoMovim, FormatTypes.decimals),
          rendNominal: helperFormat(movim.rend, FormatTypes.decimals),
          saldoFim: helperFormat(movim.saldoBruto, FormatTypes.decimals),
        };
      })
      : null;

  const columnsDadosAgrupados: ITableColumns[] = [
    {
      id: 'nome_ativo',
      label: 'Ativo',
      colorLegend: true,
      freeze: true,
      width: '300px',
      noBreak: true,
    },
    {
      id: 'sbr_ini',
      label: `Saldo Bruto ${datas_dados_ativos_agrupados?.data_ini
        ?.split('-')
        .reverse()
        .join('/')}`,
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'apl',
      label: 'Aplicações no período',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'res',
      label: 'Resgates no período',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'evento',
      label: 'Eventos',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'sbr_fim',
      label: `Saldo Bruto ${datas_dados_ativos_agrupados?.data_fim
        ?.split('-')
        .reverse()
        .join('/')}`,
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'rendimento',
      label: 'Rendimento Bruto',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'imposto_pago',
      label: 'Impostos Pagos',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'provisao_IR_IOF',
      label: 'Provisão IR+IOF',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'sli_fim',
      label: 'Saldo Líquido',
      align: AlignTypes.right,
      headerAlign: AlignTypes.center,
    },
    {
      id: 'percent_sli',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [ativoModal, setAtivoModal] = useState(false);
  const [nomeAtivoModal, setNomeAtivoModal] = useState('');

  const onRowClickHandle = async (data: any) => {
    if (!openModal && ativoModal !== data.ativo) await dispatch(fetchModalPosCon(data));
    if (!openModal) setAtivoModal(data.ativo);
    setOpenModal(!openModal);
  };

  const dadosAgrupados =
    isLoaded && dados_ativos_agrupados
      ? groupAndSumByProperties(dados_ativos_agrupados, 'classe', 'subclasse', [
        'sbr_ini',
        'apl',
        'res',
        'evento',
        'sbr_fim',
        'rendimento',
        'imposto_pago',
        'provisao_IR_IOF',
        'sli_fim',
        'percent_sli',
      ])
      : [];

  const totalAtivos =
    isLoaded && dados_ativos_agrupados
      ? sumByProperties(dados_ativos_agrupados, [
        'sbr_ini',
        'apl',
        'res',
        'evento',
        'sbr_fim',
        'rendimento',
        'imposto_pago',
        'provisao_IR_IOF',
        'sli_fim',
        'percent_sli',
      ])
      : [];

  const SiglaMes = [
    'Jan',
    'Fev',
    'Mar',
    'Abr',
    'Mai',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const assetsApi = isLoaded && graficoRetornos ? (graficoRetornos[0].slice(1) as string[]) : null;
  const dados = isLoaded && graficoRetornos ? graficoRetornos.slice(1) : null;
  const dadosEvol = isLoaded && graficoPL ? graficoPL.slice(1) : null;
  const datesApi =
    isLoaded && graficoRetornos
      ? (graficoRetornos.slice(1).map((item) => item[0]) as string[])
      : null;
  const datesTooltip = datesApi?.map((item) => item.split('-').reverse().join('/'));

  const datesAnoMes = datesApi?.map((item) => (item as string).substring(2, 7));
  datesAnoMes?.reverse();
  const dates: string[] = [];
  datesAnoMes?.forEach(function (item, i) {
    dates[i] = `${SiglaMes[Number(item.substring(3, 5)) - 1]}/${item.substring(0, 2)}`;
  });
  dates.reverse();

  const data: any = [];
  const dataEvol: any = [];
  dates.forEach((item, i) => data.push({ name: item, datesTooltip: datesTooltip![i] }));
  dates.forEach((item, i) => dataEvol.push({ name: item, datesTooltip: datesTooltip![i] }));

  assetsApi?.forEach(function (itemAsset, indexAsset) {
    if (itemAsset === 'Carteira_retorno') {
      dataEvol.forEach(function (itemData: any, i: number) {
        itemData[assetsApi[0]] = dadosEvol![i][1];
      });
    }
    data.forEach(function (itemData: any, i: number) {
      itemData[itemAsset] = dados![i][indexAsset + 1];
    });
  });

  const barChartRend12Data =
    isLoaded && rendimento12meses
      ? Object.keys(rendimento12meses)
        .reverse()
        .map((mes) => {
          const value = rendimento12meses[mes][0].rend;
          return {
            name: mes,
            value:
              typeof value === 'number'
                ? Number(value.toFixed(2))
                : Number(Number(value).toFixed(2)),
          };
        })
      : null;

  const rowsDadosAgrupados: any[] = [];
  dadosAgrupados.forEach((classe, index) => {
    // linha classe
    if (classe.class)
      rowsDadosAgrupados.push({
        nome_ativo: classe.class,
        colorIndex: index,
        bgColor: '#444444',
        color: theme.backgroundColor,
        bold: true,
        sbr_ini: '',
        apl: '',
        res: '',
        evento: '',
        sbr_fim: '',
        rendimento: '',
        imposto_pago: '',
        provisao_IR_IOF: '',
        sli_fim: '',
        percent_sli: '',
        openModal: null,
      });

    classe.subclasses.forEach((subclasse) => {
      // linha subclasse
      if (subclasse.subclass)
        rowsDadosAgrupados.push({
          nome_ativo: subclasse.subclass,
          bgColor: '#E7E7E7',
          color: theme.fontColor,
          bold: true,
          colorIndex: index,
          sbr_ini: '',
          apl: '',
          res: '',
          evento: '',
          sbr_fim: '',
          rendimento: '',
          imposto_pago: '',
          provisao_IR_IOF: '',
          sli_fim: '',
          percent_sli: '',
          openModal: null,
        });

      subclasse.values.forEach((ativo) => {
        // ativo
        rowsDadosAgrupados.push({
          nome_ativo: ativo.chave_para_modal?.data_compra_AAAAmmDD ? (
            <span>
              {/* <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} style={{ marginRight: 4 }} /> */}
              {ativo.nome_ativo}
            </span>
          ) : (
            ativo.nome_ativo
          ),
          sbr_ini: helperFormat(ativo.sbr_ini, FormatTypes.decimals, 2),
          apl: helperFormat(ativo.apl, FormatTypes.decimals, 2),
          res: helperFormat(ativo.res, FormatTypes.decimals, 2),
          evento: helperFormat(ativo.evento, FormatTypes.decimals, 2),
          sbr_fim: helperFormat(ativo.sbr_fim, FormatTypes.decimals, 2),
          rendimento: helperFormat(ativo.rendimento, FormatTypes.decimals, 2),
          imposto_pago: helperFormat(ativo.imposto_pago, FormatTypes.decimals, 2),
          provisao_IR_IOF: helperFormat(ativo.provisao_IR_IOF, FormatTypes.decimals, 2),
          sli_fim: helperFormat(ativo.sli_fim, FormatTypes.decimals, 2),
          percent_sli: helperFormat(auxFormatPercent(ativo.percent_sli), FormatTypes.decimals, 2),
          colorIndex: index,
          openModal: ativo.chave_para_modal?.data_compra_AAAAmmDD
            ? () => {
              onRowClickHandle(ativo.chave_para_modal);
              setNomeAtivoModal(ativo.nome_ativo);
            }
            : null,
          chave_para_modal: ativo.chave_para_modal,
        });
      });

      // total subclasse
      if (subclasse.subclass)
        rowsDadosAgrupados.push({
          nome_ativo: `Total ${subclasse.subclass}`,
          sbr_ini: helperFormat(subclasse.subtotal.sbr_ini, FormatTypes.decimals, 2),
          apl: helperFormat(subclasse.subtotal.apl, FormatTypes.decimals, 2),
          res: helperFormat(subclasse.subtotal.res, FormatTypes.decimals, 2),
          evento: helperFormat(subclasse.subtotal.evento, FormatTypes.decimals, 2),
          sbr_fim: helperFormat(subclasse.subtotal.sbr_fim, FormatTypes.decimals, 2),
          rendimento: helperFormat(subclasse.subtotal.rendimento, FormatTypes.decimals, 2),
          imposto_pago: helperFormat(subclasse.subtotal.imposto_pago, FormatTypes.decimals, 2),
          provisao_IR_IOF: helperFormat(
            subclasse.subtotal.provisao_IR_IOF,
            FormatTypes.decimals,
            2,
          ),
          sli_fim: helperFormat(subclasse.subtotal.sli_fim, FormatTypes.decimals, 2),
          percent_sli: helperFormat(
            auxFormatPercent(subclasse.subtotal.percent_sli),
            FormatTypes.decimals,
            2,
          ),
          colorIndex: index,
          // bgColor: theme.chartLighterColors[index % theme.chartLighterColors.length],
          bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], 0.15),
          color: theme.fontColor,
          bold: true,
          openModal: null,
        });
    });

    // total classe
    if (classe.class)
      rowsDadosAgrupados.push({
        nome_ativo: `Total ${classe.class}`,
        sbr_ini: helperFormat(classe.subtotal.sbr_ini, FormatTypes.decimals, 2),
        apl: helperFormat(classe.subtotal.apl, FormatTypes.decimals, 2),
        res: helperFormat(classe.subtotal.res, FormatTypes.decimals, 2),
        evento: helperFormat(classe.subtotal.evento, FormatTypes.decimals, 2),
        sbr_fim: helperFormat(classe.subtotal.sbr_fim, FormatTypes.decimals, 2),
        rendimento: helperFormat(classe.subtotal.rendimento, FormatTypes.decimals, 2),
        imposto_pago: helperFormat(classe.subtotal.imposto_pago, FormatTypes.decimals, 2),
        provisao_IR_IOF: helperFormat(classe.subtotal.provisao_IR_IOF, FormatTypes.decimals, 2),
        sli_fim: helperFormat(classe.subtotal.sli_fim, FormatTypes.decimals, 2),
        percent_sli: helperFormat(
          auxFormatPercent(classe.subtotal.percent_sli),
          FormatTypes.decimals,
          2,
        ),
        colorIndex: index,
        // bgColor: theme.chartLightColors[index % theme.chartLightColors.length],
        bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], 0.3),
        color: theme.fontColor,
        bold: true,
        openModal: null,
      });
  });
  if (isLoaded && dados_ativos_agrupados)
    rowsDadosAgrupados.push({
      nome_ativo: 'Total',
      bgColor: '#444444',
      color: theme.backgroundColor,
      bold: true,
      sbr_ini: helperFormat(totalAtivos.sbr_ini, FormatTypes.decimals, 2),
      apl: helperFormat(totalAtivos.apl, FormatTypes.decimals, 2),
      res: helperFormat(totalAtivos.res, FormatTypes.decimals, 2),
      evento: helperFormat(totalAtivos.evento, FormatTypes.decimals, 2),
      sbr_fim: helperFormat(totalAtivos.sbr_fim, FormatTypes.decimals, 2),
      rendimento: helperFormat(totalAtivos.rendimento, FormatTypes.decimals, 2),
      imposto_pago: helperFormat(totalAtivos.imposto_pago, FormatTypes.decimals, 2),
      provisao_IR_IOF: helperFormat(totalAtivos.provisao_IR_IOF, FormatTypes.decimals, 2),
      sli_fim: helperFormat(totalAtivos.sli_fim, FormatTypes.decimals, 2),
      percent_sli: helperFormat(100, FormatTypes.decimals, 2),
      colorIndex: null,
    });

  const columnsRetornosPC: ITableColumns[] = [
    {
      id: 'nome_ativo',
      label: 'Ativo',
      colorLegend: true,
      freeze: true,
      width: '300px',
      noBreak: true,
    },
    {
      id: 'Ret_Mes',
      label: 'Mês',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_Ano',
      label: 'Ano',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_3m',
      label: '3 meses',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_6m',
      label: '6 meses',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_12m',
      label: '12 meses',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_24m',
      label: '24 meses',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_36m',
      label: '36 meses',
      align: AlignTypes.right,
    },
    {
      id: 'Ret_periodo',
      label: 'No período',
      align: AlignTypes.right,
    },
    {
      id: 'data_compra',
      label: 'Data Compra',
      align: AlignTypes.right,
    },
    {
      id: 'percent_sli',
      label: '%',
      align: AlignTypes.right,
    },
  ];

  const retornosAtivos =
    isLoaded && retornos_ativos
      ? groupAndSumByProperties(retornos_ativos, 'classe', 'subclasse', ['percent_sli'])
      : [];

  const rowsRetornosAtivos: any[] = [];
  retornosAtivos.forEach((classe, index) => {
    // linha classe
    if (classe.class)
      rowsRetornosAtivos.push({
        nome_ativo: classe.class,
        colorIndex: index,
        bgColor: '#444444',
        color: theme.backgroundColor,
        bold: true,
        Ret_Mes: '',
        Ret_Ano: '',
        Ret_3m: '',
        Ret_6m: '',
        Ret_12m: '',
        Ret_24m: '',
        Ret_36m: '',
        Ret_periodo: '',
        data_compra: '',
        percent_sli: '',
        openModal: null,
      });

    classe.subclasses.forEach((subclasse) => {
      // linha subclasse
      if (subclasse.subclass)
        rowsRetornosAtivos.push({
          nome_ativo: subclasse.subclass,
          bgColor: '#E7E7E7',
          color: theme.fontColor,
          bold: true,
          colorIndex: index,
          Ret_Mes: '',
          Ret_Ano: '',
          Ret_3m: '',
          Ret_6m: '',
          Ret_12m: '',
          Ret_24m: '',
          Ret_36m: '',
          Ret_periodo: '',
          data_compra: '',
          percent_sli: '',
          openModal: null,
        });

      subclasse.values.forEach((ativo) => {
        // ativo
        rowsRetornosAtivos.push({
          nome_ativo: ativo.chave_para_modal?.data_compra_AAAAmmDD ? (
            <span>
              {/* <FontAwesomeIcon icon={faMagnifyingGlass as IconProp} style={{ marginRight: 4 }} /> */}
              {ativo.nome_ativo}
            </span>
          ) : (
            ativo.nome_ativo
          ),
          Ret_Mes: helperFormat(ativo.Ret_Mes, FormatTypes.decimals, 2),
          Ret_Ano: helperFormat(ativo.Ret_Ano, FormatTypes.decimals, 2),
          Ret_3m: helperFormat(ativo.Ret_3m, FormatTypes.decimals, 2),
          Ret_6m: helperFormat(ativo.Ret_6m, FormatTypes.decimals, 2),
          Ret_12m: helperFormat(ativo.Ret_12m, FormatTypes.decimals, 2),
          Ret_24m: helperFormat(ativo.Ret_24m, FormatTypes.decimals, 2),
          Ret_36m: helperFormat(ativo.Ret_36m, FormatTypes.decimals, 2),
          Ret_periodo: helperFormat(ativo.Ret_periodo, FormatTypes.decimals, 2),
          data_compra: helperFormat(ativo.data_compra, FormatTypes.text),
          percent_sli: helperFormat(auxFormatPercent(ativo.percent_sli), FormatTypes.decimals, 2),
          colorIndex: index,
          openModal: ativo.chave_para_modal?.data_compra_AAAAmmDD
            ? () => {
              onRowClickHandle(ativo.chave_para_modal);
              setNomeAtivoModal(ativo.nome_ativo);
            }
            : null,
          chave_para_modal: ativo.chave_para_modal,
        });
        if (ativo.retornos_benchmarks && ativo.retornos_benchmarks.length > 0) {
          for (let i = 0; i < ativo.retornos_benchmarks.length; i++) {
            rowsRetornosAtivos.push({
              nome_ativo: ativo.retornos_benchmarks[i].nome_benchmark.replace(/Percent /i, '%'),
              Ret_Mes: helperFormat(
                ativo.retornos_benchmarks[i].Ret_Mes_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_Ano: helperFormat(
                ativo.retornos_benchmarks[i].Ret_Ano_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_3m: helperFormat(
                ativo.retornos_benchmarks[i].Ret_3m_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_6m: helperFormat(
                ativo.retornos_benchmarks[i].Ret_6m_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_12m: helperFormat(
                ativo.retornos_benchmarks[i].Ret_12m_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_24m: helperFormat(
                ativo.retornos_benchmarks[i].Ret_24m_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_36m: helperFormat(
                ativo.retornos_benchmarks[i].Ret_36m_benchmark,
                FormatTypes.decimals,
                2,
              ),
              Ret_periodo: helperFormat(
                ativo.retornos_benchmarks[i].Ret_periodo_benchmark,
                FormatTypes.decimals,
                2,
              ),
              data_compra: '-',
              percent_sli: '-',
              colorIndex: index,
            });
          }
        }
      });

      // total subclasse
      if (subclasse.subclass)
        rowsRetornosAtivos.push({
          nome_ativo: `Total ${subclasse.subclass}`,
          Ret_Mes: '',
          Ret_Ano: '',
          Ret_3m: '',
          Ret_6m: '',
          Ret_12m: '',
          Ret_24m: '',
          Ret_36m: '',
          Ret_periodo: '',
          data_compra: '',
          percent_sli: helperFormat(
            auxFormatPercent(subclasse.subtotal.percent_sli),
            FormatTypes.decimals,
            2,
          ),
          colorIndex: index,
          // bgColor: theme.chartLighterColors[index % theme.chartLighterColors.length],
          bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], 0.15),
          color: theme.fontColor,
          bold: true,
          openModal: null,
        });
    });

    // total classe
    if (classe.class)
      rowsRetornosAtivos.push({
        nome_ativo: `Total ${classe.class}`,
        Ret_Mes: '',
        Ret_Ano: '',
        Ret_3m: '',
        Ret_6m: '',
        Ret_12m: '',
        Ret_24m: '',
        Ret_36m: '',
        Ret_periodo: '',
        data_compra: '',
        percent_sli: helperFormat(
          auxFormatPercent(classe.subtotal.percent_sli),
          FormatTypes.decimals,
          2,
        ),
        colorIndex: index,
        // bgColor: theme.chartLightColors[index % theme.chartLightColors.length],
        bgColor: hexToRGBA(theme.chartColors[index % theme.chartColors.length], 0.3),
        color: theme.fontColor,
        bold: true,
        openModal: null,
      });
  });

  const getCode = function (index: number) {
    return String.fromCharCode(65 + (index % 26)).repeat(Math.floor(index / 26) + 1);
  };

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (arg: any) => {
    const x = arg.cx + (arg.outerRadius + 10) * Math.cos(-arg.midAngle * RADIAN);
    const y = arg.cy + (arg.outerRadius + 10) * Math.sin(-arg.midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > arg.cx ? 'start' : 'end'}
        dominantBaseline="central">
        {getCode(arg.index)}
      </text>
    );
  };

  const rowsRetornoPDF = rowsRetorno
    ? rowsRetorno.map((obj) => [obj.ativo, obj.mes, obj.ano, obj.mes12, obj.mes24, obj.periodo])
    : [];

  const rowsClassePDF = rowsClasse
    ? rowsClasse.map((obj) => [obj.classe, obj.saldo, obj.percent, obj.mes, obj.ano])
    : [];

  const rowsLiqPDF = rowsLiq ? rowsLiq.map((obj) => [obj.periodo, obj.valor, obj.percent]) : [];

  const rowsMovimPDF = rowsMovim
    ? rowsMovim.map((obj) => [obj.mesano, obj.saldoMovim, obj.saldoBruto])
    : [];

  const rowsInfos1PDF = rowsInfos1
    ? rowsInfos1.map((obj) => [obj.info, obj.carteira, obj.bench])
    : [];

  const rowsInfos2PDF = rowsInfos2
    ? rowsInfos2.map((obj) => [obj.info, obj.valor, obj.percent])
    : [];

  const rowsRetornosPDF = rowsRetornos ?? [];

  const rowsVolPDF = rowsVol ?? [];

  const rowsSharpePDF = rowsSharpe ?? [];

  // ABA CARTEIRA EXPLODIDA
  // const columnsCarteiraExplodidaPDF: ITableColumns[] = [
  //   {
  //     id: 'nome_ativo',
  //     label: 'Ativo Final',
  //     colorLegend: true,
  //     freeze: true,
  //     width: '300px',
  //     noBreak: true,
  //   },
  //   {
  //     id: 'sbr_fim',
  //     label: `Saldo Bruto`,
  //     align: AlignTypes.right,
  //     headerAlign: AlignTypes.right,
  //   },
  //   {
  //     id: 'percent_sbr',
  //     label: '% do Saldo Bruto',
  //     align: AlignTypes.right,
  //     headerAlign: AlignTypes.right,
  //   },
  // ];

  // const dadosAgrupadosCartExp =
  //   isLoaded && dados_cart_explodida
  //     ? groupAndSumByProperties(dados_cart_explodida, 'classe', 'subclasse', [
  //       'sbr_fim',
  //       'percent_sbr',
  //     ])
  //     : [];

  // const totalAtivosCartExp =
  //   isLoaded && dados_cart_explodida
  //     ? sumByProperties(dados_cart_explodida, [
  //       'sbr_fim',
  //       'percent_sbr',
  //     ])
  //     : [];

  // const rowsDadosAgrupadosCartExp: any[] = [];
  // const alocClasseCartExp: any[] = [];   // dados para grafico pizza Carteira Explodida

  // dadosAgrupadosCartExp.forEach((classe, index) => {
  //   // linha classe
  //   if (classe.class) rowsDadosAgrupadosCartExp.push({
  //     nome_ativo: classe.class,
  //     colorIndex: index,
  //     bgColor: '#444444',
  //     color: theme.backgroundColor,
  //     bold: true,
  //     sbr_fim: '',
  //     percent_sbr: '',
  //     openModal: null,
  //   });

  //   classe.subclasses.forEach((subclasse) => {
  //     // linha subclasse
  //     if (subclasse.subclass) rowsDadosAgrupadosCartExp.push({
  //       nome_ativo: subclasse.subclass,
  //       bgColor: '#E7E7E7',
  //       color: theme.fontColor,
  //       bold: true,
  //       colorIndex: index,
  //       sbr_fim: '',
  //       percent_sbr: '',
  //       openModal: null,
  //     });

  //     subclasse.values.forEach((ativo) => {
  //       // ativo
  //       rowsDadosAgrupadosCartExp.push({
  //         nome_ativo: ativo.nome_ativo,
  //         sbr_fim: helperFormat(ativo.sbr_fim, FormatTypes.decimals, 2),
  //         percent_sbr: helperFormat(auxFormatPercent(ativo.percent_sbr), FormatTypes.decimals, 2),
  //         colorIndex: index,
  //       });
  //     });

  //     // total subclasse
  //     if (subclasse.subclass) rowsDadosAgrupadosCartExp.push({
  //       nome_ativo: `Total ${subclasse.subclass}`,
  //       sbr_fim: helperFormat(subclasse.subtotal.sbr_fim, FormatTypes.decimals, 2),
  //       percent_sbr: helperFormat(
  //         auxFormatPercent(subclasse.subtotal.percent_sbr),
  //         FormatTypes.decimals,
  //         2,
  //       ),
  //       colorIndex: index,
  //       bgColor: theme.chartLighterColors[index % theme.chartLighterColors.length],
  //       color: theme.fontColor,
  //       bold: true,
  //       openModal: null,
  //     });
  //   });

  //   // total classe
  //   if (classe.class) rowsDadosAgrupadosCartExp.push({
  //     nome_ativo: `Total ${classe.class}`,
  //     sbr_fim: helperFormat(classe.subtotal.sbr_fim, FormatTypes.decimals, 2),
  //     percent_sbr: helperFormat(
  //       auxFormatPercent(classe.subtotal.percent_sbr),
  //       FormatTypes.decimals,
  //       2,
  //     ),
  //     colorIndex: index,
  //     bgColor: theme.chartLightColors[index % theme.chartLightColors.length],
  //     color: theme.fontColor,
  //     bold: true,
  //     openModal: null,
  //   });

  //   // dados para grafico pizza(total da classe) - Carteira Explodida
  //   if (classe.class) alocClasseCartExp.push({
  //     classe: `${classe.class}`,
  //     alocacao: classe.subtotal.percent_sbr,
  //   });
  // });
  // if (isLoaded && dados_cart_explodida)
  //   rowsDadosAgrupadosCartExp.push({
  //     nome_ativo: 'Total',
  //     bgColor: '#444444',
  //     color: theme.backgroundColor,
  //     bold: true,
  //     sbr_fim: helperFormat(totalAtivosCartExp.sbr_fim, FormatTypes.decimals, 2),
  //     percent_sbr: helperFormat(100, FormatTypes.decimals, 2),
  //     colorIndex: null,
  //   });
  // const pieChartCartExp =
  //   isLoaded && alocClasseCartExp
  //     ? alocClasseCartExp
  //       .map((obj) => ({
  //         name: obj.classe !== '' ? obj.classe : 'Outros',
  //         value:
  //           typeof obj.alocacao === 'number'
  //             ? Number((obj.alocacao * 100).toFixed(2))
  //             : Number((Number(obj.alocacao) * 100).toFixed(2)),
  //       }))
  //       .sort((a, b) => (a.value > b.value ? -1 : b.value > a.value ? 1 : 0))
  //     : null;

  // ABA FLUXO DE ATIVOS
  const rowsFluxoAtivosPDF = rowsFluxoAtivos
    ? rowsFluxoAtivos.map((obj) => [
      obj.data,
      obj.tipo,
      obj.desc,
      obj.op,
      obj.quant,
      obj.valBruto,
      obj.valLiquido,
    ])
    : [];

  const rowsRetornosPHPDF = rowsRetornosPH
    ? rowsRetornosPH.map((obj) => [
      obj.ativo,
      obj.Jan,
      obj.Fev,
      obj.Mar,
      obj.Abr,
      obj.Mai,
      obj.Jun,
      obj.Jul,
      obj.Ago,
      obj.Set,
      obj.Out,
      obj.Nov,
      obj.Dez,
      obj.Ano,
      obj.Acum,
    ])
    : [];

  const rowsRetornosPHAno = rowsRetornosPH
    ? rowsRetornosPH.map((obj) => [obj?.ano]).filter((item) => item !== (null || undefined))
    : [];

  const rowsRetornosPHAnoPDF = rowsRetornosPHAno.filter(
    (item) => Array.isArray(item) && typeof item[0] === 'string',
  );

  const rowsMovimPHPDF = rowsMovimPH
    ? rowsMovimPH.map((obj) => [
      obj.mesano,
      obj.saldoIni,
      obj.aplicacoes,
      obj.resgates,
      obj.impostos,
      obj.saldoMovim,
      obj.rendNominal,
      obj.saldoFim,
    ])
    : [];

  const rowsDadosAgrupadosPDF = rowsDadosAgrupados
    ? rowsDadosAgrupados.map((obj) => {
      if (typeof obj.nome_ativo === 'string') {
        return [
          obj.nome_ativo,
          obj.sbr_ini,
          obj.apl,
          obj.res,
          obj.evento,
          obj.sbr_fim,
          obj.rendimento,
          obj.imposto_pago,
          obj.provisao_IR_IOF,
          obj.sli_fim,
          obj.percent_sli,
        ];
      } else if (typeof obj.nome_ativo === 'object' && obj.nome_ativo.props.children) {
        return [
          obj.nome_ativo.props.children,
          obj.sbr_ini,
          obj.apl,
          obj.res,
          obj.evento,
          obj.sbr_fim,
          obj.rendimento,
          obj.imposto_pago,
          obj.provisao_IR_IOF,
          obj.sli_fim,
          obj.percent_sli,
        ];
      } else {
        return [
          'Nome ativo',
          obj.sbr_ini,
          obj.apl,
          obj.res,
          obj.evento,
          obj.sbr_fim,
          obj.rendimento,
          obj.imposto_pago,
          obj.provisao_IR_IOF,
          obj.sli_fim,
          obj.percent_sli,
        ];
      }
    })
    : [];

  const rowsRetornosAtivosPDF = rowsRetornosAtivos
    ? rowsRetornosAtivos.map((obj) => {
      if (typeof obj.nome_ativo === 'string') {
        return [
          obj.nome_ativo,
          obj.Ret_Mes,
          obj.Ret_Ano,
          obj.Ret_3m,
          obj.Ret_6m,
          obj.Ret_12m,
          obj.Ret_24m,
          obj.Ret_36m,
          obj.Ret_periodo,
          obj.data_compra,
          obj.percent_sli,
        ];
      } else if (typeof obj.nome_ativo === 'object' && obj.nome_ativo.props.children) {
        return [
          obj.nome_ativo.props.children,
          obj.Ret_Mes,
          obj.Ret_Ano,
          obj.Ret_3m,
          obj.Ret_6m,
          obj.Ret_12m,
          obj.Ret_24m,
          obj.Ret_36m,
          obj.Ret_periodo,
          obj.data_compra,
          obj.percent_sli,
        ];
      } else {
        return [
          'Nome ativo',
          obj.sbr_ini,
          obj.apl,
          obj.res,
          obj.evento,
          obj.sbr_fim,
          obj.rendimento,
          obj.imposto_pago,
          obj.provisao_IR_IOF,
          obj.sli_fim,
          obj.percent_sli,
        ];
      }
    })
    : [];

  let headerCartXBenchPDF: RowInput[] = [];
  if (params.periodo !== '12m' && params.periodo !== '24m' && params.periodo !== 'mes_atual') {
    headerCartXBenchPDF = [
      [
        { content: columnsRetorno[0].label, styles: { halign: 'left' } },
        columnsRetorno[1].label,
        columnsRetorno[2].label,
        columnsRetorno[3].label,
        columnsRetorno[4].label,
        columnsRetorno[5]?.label,
      ],
    ];
  } else {
    headerCartXBenchPDF = [
      [
        { content: columnsRetorno[0].label, styles: { halign: 'left' } },
        columnsRetorno[1].label,
        columnsRetorno[2].label,
        columnsRetorno[3].label,
        columnsRetorno[4].label,
      ],
    ];
  }

  //Criando tabela para a Performance Histórica com o rowSpan apropriado para os anos

  const rowsPHCompletoPDF: RowInput[] = [];
  let j = 1;
  for (let i = 0; i < rowsRetornosPHAnoPDF.length; i++) {
    let rowTemp: CellInput[] = [];
    rowTemp.push({
      content: rowsRetornosPHAnoPDF[i][0],
      rowSpan: rowsRetornosPHPDF.length / rowsRetornosPHAnoPDF.length,
      styles: {
        valign: 'middle',
        fillColor: theme.titleFontColor,
        halign: 'center',
        textColor: '#FFFFFF',
        cellWidth: 10,
        lineColor: '#FFFFFF',
        lineWidth: 1,
      },
    });
    while (j - 1 < ((i + 1) * rowsRetornosPHPDF.length) / rowsRetornosPHAnoPDF.length) {
      for (let k = 0; k < rowsRetornosPHPDF[0].length; k++) {
        rowTemp.push({ content: rowsRetornosPHPDF[j - 1][k] });
      }
      j = j + 1;
      rowsPHCompletoPDF.push(rowTemp);
      rowTemp = [];
    }
  }

  //Adicionando cor alternada para a tabela na Posição Consolidada

  j = 0;
  let rowsDadosAgrupadosPDFCor: RowInput[] | undefined = undefined;
  if (rowsDadosAgrupados) {
    rowsDadosAgrupadosPDFCor = rowsDadosAgrupados.map((obj) => {
      const chavesRowsDadosAgrupados = [
        'nome_ativo',
        'sbr_ini',
        'apl',
        'res',
        'evento',
        'sbr_fim',
        'rendimento',
        'imposto_pago',
        'provisao_IR_IOF',
        'sli_fim',
        'percent_sli',
      ];

      j = j + 1;
      const rowPCTemp = chavesRowsDadosAgrupados.map((chavePC) => {
        if (chavePC === 'nome_ativo' && typeof obj[chavePC] === 'string') {
          return {
            content: obj[chavePC],
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        } else if (
          chavePC === 'nome_ativo' &&
          typeof obj[chavePC] === 'object' &&
          obj[chavePC].props.children
        ) {
          return {
            content: obj[chavePC].props.children,
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        } else {
          return {
            content: obj[chavePC],
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        }
      });

      return rowPCTemp;
    });
  }

  j = 0;
  let rowsRetornosAtivosPDFCor: RowInput[] | undefined = undefined;
  if (rowsRetornosAtivos) {
    rowsRetornosAtivosPDFCor = rowsRetornosAtivos.map((obj) => {
      const chavesRowsDadosAgrupados = [
        'nome_ativo',
        'Ret_Mes',
        'Ret_Ano',
        'Ret_3m',
        'Ret_6m',
        'Ret_12m',
        'Ret_24m',
        'Ret_36m',
        'Ret_periodo',
        'data_compra',
        'percent_sli',
      ];

      j = j + 1;
      const rowPCTemp = chavesRowsDadosAgrupados.map((chavePC) => {
        if (chavePC === 'nome_ativo' && typeof obj[chavePC] === 'string') {
          return {
            content: obj[chavePC],
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        } else if (
          chavePC === 'nome_ativo' &&
          typeof obj[chavePC] === 'object' &&
          obj[chavePC].props.children
        ) {
          return {
            content: obj[chavePC].props.children,
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        } else {
          return {
            content: obj[chavePC],
            styles: {
              fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
              textColor: obj.color ? obj.color : '#555555',
            },
          };
        }
      });

      return rowPCTemp;
    });
  }

  //Adicionando cor na esquerda da tabela de Posição e Rendimento por Classe

  const listaRowsClassePDFCor = chartColors;

  const rowsClassePDFCor: RowInput[] = [];
  let tempClasseCor = 0;
  for (let i = 0; i < rowsClassePDF.length; i++) {
    let rowTemp: CellInput[] = [];
    rowTemp.push({
      content: '',
      styles: {
        fillColor:
          rowsClasse?.[i].colorIndex !== false ? listaRowsClassePDFCor[tempClasseCor] : '#D9D9D9',
        textColor: '#FFFFFF',
        lineColor: '#FFFFFF',
        cellWidth: 3,
        lineWidth: 1,
      },
    });
    if (tempClasseCor <= listaRowsClassePDFCor.length - 2) {
      tempClasseCor = tempClasseCor + 1;
    } else {
      tempClasseCor = 0;
    }
    for (let j = 0; j < rowsClassePDF[0].length; j++) {
      rowTemp.push({ content: rowsClassePDF[i][j], styles: { valign: 'middle' } });
    }
    rowsClassePDFCor.push(rowTemp);
    rowTemp = [];
  }

  const auxLegenda = [
    'A. ',
    'B. ',
    'C. ',
    'D. ',
    'E. ',
    'F. ',
    'G. ',
    'H. ',
    'I. ',
    'J. ',
    'K. ',
    'L. ',
    'M. ',
    'N. ',
    'O. ',
    'P. ',
    'Q. ',
    'R. ',
    'S. ',
    'T. ',
    'U. ',
    'V. ',
    'W. ',
    'X. ',
    'Y. ',
    'Z. ',
  ];

  // criar rows com cor da Carteira Explodida
  // j = 0;
  // let rowsDadosAgrupadosCartExpPDFCor: RowInput[] | undefined = undefined;
  // if (rowsDadosAgrupados) {
  //   rowsDadosAgrupadosCartExpPDFCor = rowsDadosAgrupadosCartExp.map((obj) => {
  //     const chavesRowsDadosAgrupadosCartExp = [
  //       'nome_ativo',
  //       'sbr_fim',
  //       'percent_sbr',
  //     ];

  //     j = j + 1;
  //     const rowPCTemp = chavesRowsDadosAgrupadosCartExp.map((chavePC) => {
  //       if (chavePC === 'nome_ativo' && typeof obj[chavePC] === 'string') {
  //         return {
  //           content: obj[chavePC],
  //           styles: {
  //             fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
  //             textColor: obj.color ? obj.color : '#555555',
  //           },
  //         };
  //       } else if (
  //         chavePC === 'nome_ativo' &&
  //         typeof obj[chavePC] === 'object' &&
  //         obj[chavePC].props.children
  //       ) {
  //         return {
  //           content: obj[chavePC].props.children,
  //           styles: {
  //             fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
  //             textColor: obj.color ? obj.color : '#555555',
  //           },
  //         };
  //       } else {
  //         return {
  //           content: obj[chavePC],
  //           styles: {
  //             fillColor: obj.bgColor ? obj.bgColor : j % 2 === 0 ? '#FFFFFF' : '#F5F5F5',
  //             textColor: obj.color ? obj.color : '#555555',
  //           },
  //         };
  //       }
  //     });

  //     return rowPCTemp;
  //   });
  // }

  // const rowsLegendaPizzaCartExp: RowInput[] = [];
  // let legendaPizzaColorTempCartExp = 0;
  // if (pieChartCartExp) {
  //   for (let i = 0; i < pieChartCartExp.length; i++) {
  //     let rowTemp: CellInput[] = [];
  //     if (pieChartCartExp[i]) {
  //       rowTemp.push({
  //         content: '',
  //         styles: {
  //           fillColor: theme.chartColors[legendaPizzaColorTempCartExp],
  //           textColor: '#FFFFFF',
  //           lineColor: '#FFFFFF',
  //           cellWidth: 5.4,
  //           minCellHeight: 1,
  //           lineWidth: 2,
  //           fontSize: 0.5,
  //         },
  //       });
  //       rowTemp.push({
  //         content: auxLegenda[i] + pieChartCartExp[i].name,
  //         styles: { halign: 'left', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
  //       });
  //       rowTemp.push({
  //         content: helperFormat(pieChartCartExp[i].value, FormatTypes.percentage, 2),
  //         styles: { halign: 'right', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
  //       });
  //     }
  //     if (legendaPizzaColorTempCartExp <= theme.chartColors.length - 2) {
  //       legendaPizzaColorTempCartExp = legendaPizzaColorTempCartExp + 1;
  //     } else {
  //       legendaPizzaColorTempCartExp = 0;
  //     }
  //     i = i + 1;
  //     if (pieChartCartExp[i]) {
  //       rowTemp.push({
  //         content: '',
  //         styles: {
  //           fillColor: theme.chartColors[legendaPizzaColorTempCartExp],
  //           textColor: '#FFFFFF',
  //           lineColor: '#FFFFFF',
  //           cellWidth: 5.4,
  //           minCellHeight: 1,
  //           lineWidth: 2,
  //           fontSize: 0.5,
  //         },
  //       });
  //       rowTemp.push({
  //         content: auxLegenda[i] + pieChartCartExp[i].name,
  //         styles: { halign: 'left', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
  //       });
  //       rowTemp.push({
  //         content: helperFormat(pieChartCartExp[i].value, FormatTypes.percentage, 2),
  //         styles: { halign: 'right', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
  //       });
  //     }
  //     if (legendaPizzaColorTempCartExp <= theme.chartColors.length - 2) {
  //       legendaPizzaColorTempCartExp = legendaPizzaColorTempCartExp + 1;
  //     } else {
  //       legendaPizzaColorTempCartExp = 0;
  //     }
  //     rowsLegendaPizzaCartExp.push(rowTemp);
  //     rowTemp = [];
  //   }
  // }

  const rowsLegendaPizza1: RowInput[] = [];
  if (pieChartClassData) {
    for (let i = 0; i < pieChartClassData.length; i++) {
      let rowTemp: CellInput[] = [];
      if (pieChartClassData[i]) {
        rowTemp.push({
          content: '',
          styles: {
            fillColor: pieChartClassData[i].color
              ? pieChartClassData[i].color
              : theme.chartColors[i],
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            cellWidth: 5.4,
            minCellHeight: 0.1,
            lineWidth: 2,
            fontSize: 0.1,
          },
        });
        rowTemp.push({
          content: auxLegenda[i] + pieChartClassData[i].name,
          styles: { halign: 'left', fontSize: 5, minCellHeight: 0.1, valign: 'middle' },
        });
        rowTemp.push({
          content: helperFormat(pieChartClassData[i].value, FormatTypes.percentage, 2),
          styles: { halign: 'right', fontSize: 5, minCellHeight: 0.1, valign: 'middle' },
        });
      }
      i = i + 1;
      if (pieChartClassData[i]) {
        rowTemp.push({
          content: '',
          styles: {
            fillColor: pieChartClassData[i].color
              ? pieChartClassData[i].color
              : theme.chartColors[i],
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            cellWidth: 5.4,
            minCellHeight: 0.1,
            lineWidth: 2,
            fontSize: 0.1,
          },
        });
        rowTemp.push({
          content: auxLegenda[i] + pieChartClassData[i].name,
          styles: { halign: 'left', fontSize: 5, minCellHeight: 0.1, valign: 'middle' },
        });
        rowTemp.push({
          content: helperFormat(pieChartClassData[i].value, FormatTypes.percentage, 2),
          styles: { halign: 'right', fontSize: 5, minCellHeight: 0.1, valign: 'middle' },
        });
      }
      rowsLegendaPizza1.push(rowTemp);
      rowTemp = [];
    }
  }
  const rowsLegendaPizza2: RowInput[] = [];
  let legendaPizzaColorTemp2 = 0;
  if (pieChartCustodianteData) {
    for (let i = 0; i < pieChartCustodianteData.length; i++) {
      let rowTemp: CellInput[] = [];
      if (pieChartCustodianteData[i]) {
        rowTemp.push({
          content: '',
          styles: {
            fillColor: theme.chartColors[legendaPizzaColorTemp2],
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            cellWidth: 5.4,
            minCellHeight: 1,
            lineWidth: 2,
            fontSize: 0.5,
          },
        });
        rowTemp.push({
          content: auxLegenda[i] + pieChartCustodianteData[i].name,
          styles: { halign: 'left', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
        });
        rowTemp.push({
          content: helperFormat(pieChartCustodianteData[i].value, FormatTypes.percentage, 2),
          styles: { halign: 'right', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
        });
      }
      if (legendaPizzaColorTemp2 <= theme.chartColors.length - 2) {
        legendaPizzaColorTemp2 = legendaPizzaColorTemp2 + 1;
      } else {
        legendaPizzaColorTemp2 = 0;
      }
      i = i + 1;
      if (pieChartCustodianteData[i]) {
        rowTemp.push({
          content: '',
          styles: {
            fillColor: theme.chartColors[legendaPizzaColorTemp2],
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            cellWidth: 5.4,
            minCellHeight: 1,
            lineWidth: 2,
            fontSize: 0.5,
          },
        });
        rowTemp.push({
          content: auxLegenda[i] + pieChartCustodianteData[i].name,
          styles: { halign: 'left', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
        });
        rowTemp.push({
          content: helperFormat(pieChartCustodianteData[i].value, FormatTypes.percentage, 2),
          styles: { halign: 'right', fontSize: 5, minCellHeight: 0.5, valign: 'middle' },
        });
      }
      if (legendaPizzaColorTemp2 <= theme.chartColors.length - 2) {
        legendaPizzaColorTemp2 = legendaPizzaColorTemp2 + 1;
      } else {
        legendaPizzaColorTemp2 = 0;
      }
      rowsLegendaPizza2.push(rowTemp);
      rowTemp = [];
    }
  }

  // Criar tabela para a legenda do grafico de linha(Retorno Acumulado)
  const rowsLegendaLinha: RowInput[] = [];
  if (assetsApi) {
    let rowTemp: CellInput[] = [];
    for (let i = 0; i < assetsApi.length; i++) {
      if (assetsApi[i]) {
        rowTemp.push({
          content: '',
          styles: {
            fillColor: theme.lineChartColors[i % theme.lineChartColors.length],
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            cellWidth: 5,
            minCellHeight: 0.1,
            lineWidth: 2,
            fontSize: 0.1,
          },
        });
        rowTemp.push({
          content: assetsApi[i] === 'Carteira_retorno' ? 'Carteira' : assetsApi[i],
          styles: { halign: 'left', fontSize: 6, minCellHeight: 0.1, valign: 'middle', cellWidth: 20 },
        });
      }
      if((i+1)%4 === 0 || i === assetsApi.length-1){
        rowsLegendaLinha.push(rowTemp);
        rowTemp = [];
      }
    }
  }
  // Criar tabela para a legenda do grafico de area (Evolução do Patrimonio)
  const rowsLegendaArea: RowInput[] = [];
  const rowTempArea: CellInput[] = [];
  rowTempArea.push({
    content: '',
    styles: {
      fillColor: theme.lineChartColors[0],
      textColor: '#FFFFFF',
      lineColor: '#FFFFFF',
      cellWidth: 5,
      minCellHeight: 0.1,
      lineWidth: 2,
      fontSize: 0.1,
    },
  });
  rowTempArea.push({
    content: 'Carteira',
    styles: { fontSize: 6, minCellHeight: 0.1},
  });
  rowsLegendaArea.push(rowTempArea);

  const espacamentoRC1 = rowsRetornoPDF.length - 4;
  const espacamentoRC3 = rowsLiqPDF.length;
  let espacoGeral = 0;

  const [gerandoPDF, setGerandoPDF] = useState(true);
  const navigate = useNavigate();

  const containerRefRC0 = useRef<HTMLDivElement>(null);
  const containerRefRC1 = useRef<HTMLDivElement>(null);
  const containerRefRC2 = useRef<HTMLDivElement>(null);
  const containerRefPH1 = useRef<HTMLDivElement>(null);
  const containerRefPH2 = useRef<HTMLDivElement>(null);
  const containerRefPH3 = useRef<HTMLDivElement>(null);
  const containerRefPC = useRef<HTMLDivElement>(null);
  // const containerRefCE0 = useRef<HTMLDivElement>(null);
  // const containerRefCE1 = useRef<HTMLDivElement>(null);
  const containerRefFA = useRef<HTMLDivElement>(null);
  const containerRefFC = useRef<HTMLDivElement>(null);
  const containerRefEA = useRef<HTMLDivElement>(null);

  const screenWidth = window.innerWidth;

  const adicionarImagemPDF = (
    doc: jsPDF,
    imgData: any,
    posicaoX: number,
    posicaoY: number,
    width: number,
    height: number,
    recur: boolean,
    espacoGeral: number,
  ) => {
    try {
      return doc.addImage(imgData, 'JPEG', posicaoX, posicaoY, width, height);
    } catch {
      if (recur) {
        generatePDFAll(false);
        try {
          return doc.addImage(imgData, 'JPEG', posicaoX, posicaoY, width, height);
        } catch {
          if (typeof espacoGeral === 'number') {
            espacoGeral = espacoGeral - height + 2;
          }
          return espacoGeral;
        }
      }
    }
  };

  /**
   * Adicionar a imagem no PDF utilizando a biblioteca svg2pdf
   * Retorna uma Promise para adicionar o svg na pagina
   */
  const adicionarSvgPDF = async (doc: jsPDF,imgData: any, x: number, y: number, width: number, height: number) => {
    return await doc.svg(imgData, {
      x,
      y,
      width,
      height
    })
  }

  const generatePDFAll = async (recur: boolean) => {
    const containerElementPH1 = containerRefPH1.current?.querySelector('svg');
    const containerElementPH2 = containerRefPH2.current?.querySelector('svg');
    const containerElementPH3 = containerRefPH3.current?.querySelector('svg');
    const containerElementRC0 = containerRefRC0.current;  // cards do resumo carteira
    const containerElementRC1 = containerRefRC1.current?.querySelector('svg');
    const containerElementRC2 = containerRefRC2.current?.querySelector('svg');
    // const containerElementCE1 = containerRefCE1.current?.querySelector('svg');

    const listaPDF = [
      containerElementRC0,
      containerElementRC1,
      containerElementRC2,
      containerElementPH1,
      containerElementPH2,
      containerElementPH3,
      // containerElementCE1,
    ];

    const imgHeightPDF = [screenWidth * 0.095, 550, 550, 400, 400, 435, 550, 550, 550, 550];
    const imgWidthPDF = [screenWidth * 0.95, 400, 400, 1200, 1200, 1200, 400, 450, 400, 450];

    const doc = new jsPDF();
    const imgData: any = [];
    let finalY = (doc as any).lastAutoTable.finalY;
    let finalY2 = (doc as any).lastAutoTable.finalY;

    const datasIni = obj_datas.data_ini.split('/');
    const data_iniPDF = `_${datasIni[2]}${datasIni[1]}${datasIni[0]}`;
    const datasFim = obj_datas.data_fim.split('/');
    const data_fimPDF = `_${datasFim[2]}${datasFim[1]}${datasFim[0]}`;
    const dataAtual_timezone = new Date().toLocaleString("en-US", {timeZone: "America/Sao_Paulo"});
    const dataAtual = new Date(dataAtual_timezone);
    const anoElaboracao = dataAtual.getFullYear().toString();
    const mesElaboracao = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    const diaElaboracao = dataAtual.getDate().toString().padStart(2, '0');
    const dataElaboracao = '_' + anoElaboracao + mesElaboracao + diaElaboracao;
    const dataElaboracaoRodape =  diaElaboracao + '/' + mesElaboracao + '/' + anoElaboracao;

    let dataCapaPDF = '';

    switch (datasFim[1]) {
      case '01':
        dataCapaPDF = 'JANEIRO';
        break;
      case '02':
        dataCapaPDF = 'FEVEREIRO';
        break;
      case '03':
        dataCapaPDF = 'MARÇO';
        break;
      case '04':
        dataCapaPDF = 'ABRIL';
        break;
      case '05':
        dataCapaPDF = 'MAIO';
        break;
      case '06':
        dataCapaPDF = 'JUNHO';
        break;
      case '07':
        dataCapaPDF = 'JULHO';
        break;
      case '08':
        dataCapaPDF = 'AGOSTO';
        break;
      case '09':
        dataCapaPDF = 'SETEMBRO';
        break;
      case '10':
        dataCapaPDF = 'OUTUBRO';
        break;
      case '11':
        dataCapaPDF = 'NOVEMBRO';
        break;
      case '12':
        dataCapaPDF = 'DEZEMBRO';
        break;
    }

    const alias_portfolio = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === params.carteira?.toUpperCase())[0].alias_nome_portfolio;

    listaPDF.map(async (element, index) => {
      if (element) {
            imgData[index] = element;

            // adicionar fonte 'Open Sans' no svg
            if(element instanceof SVGElement){
              const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
              style.textContent = `
                text {
                  font-family: OpenSans-Regular, OpenSans-Bold;
                  font-size:16px;
                }`;
              element.appendChild(style);
            }

            if (index >= listaPDF.length - 1) {
              const catchImagemCapa = adicionarImagemPDF(
                doc,
                CapaPDF,
                0,
                0,
                210,
                300,
                recur,
                espacoGeral,
              );
              if (typeof catchImagemCapa != 'number') {
                doc.setTextColor(theme.fontColorMenu);
                doc.setFontSize(16);
                doc.text(dataCapaPDF + ' | ' + datasFim[2] + ' - ' + (alias_portfolio ?? carteira), 106, 281, { maxWidth: 100 });
                doc.addPage();
              }
              doc.setTextColor(theme.titleFontColor);
              /////////////////////////////////////////////////////////////////////////////////////////////////////RC
              espacoGeral = espacoGeral + 22;
              doc.setFontSize(14);
              doc.text('Resumo Carteira', 6, espacoGeral);
              espacoGeral = espacoGeral + 3;

              // adicionar os cards no PDF. Utiliza a biblioteca html2canvas
              if (containerElementRC0) {
                await doc.html(containerElementRC0, {
                  x: 10,
                  y: 323,
                  html2canvas: {
                      scale: 0.20,
                      windowWidth: 1300,
                      windowHeight: 100
                  }
                });
              }

              espacoGeral = espacoGeral + 30;
              doc.setTextColor(theme.titleFontColor);
              doc.setFontSize(8);
              if (rowsRetornoPDF.length > 0) {
                doc.text(`Retorno da Carteira x Benchmarks. Período de análise: de ${obj_datas.data_ini} a ${obj_datas.data_fim}`, 6, espacoGeral);
              }
              if (rowsLiqPDF.length > 0) {
                doc.text('Fluxo de Resgate', 141, espacoGeral);
              }
              espacoGeral = espacoGeral + 3;
              if (rowsRetornoPDF.length > 0) {
                autoTable(doc, {
                  margin: { left: 5 },
                  tableWidth: 132,
                  startY: espacoGeral,
                  headStyles: { halign: 'right', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 7,
                  },
                  columnStyles: { 0: { halign: 'left' } },
                  head: headerCartXBenchPDF,
                  body: rowsRetornoPDF,
                });
              }
              if (rowsLiqPDF.length > 0) {
                autoTable(doc, {
                  margin: { left: 140 },
                  startY: espacoGeral,
                  tableWidth: 64,
                  headStyles: { halign: 'right', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 7,
                  },
                  columnStyles: { 0: { halign: 'left' } },
                  head: [
                    [
                      { content: columnsLiq[0].label, styles: { halign: 'left' } },
                      columnsLiq[1].label,
                      columnsLiq[2].label,
                    ],
                  ],
                  body: rowsLiqPDF,
                });
              }
              espacoGeral = espacoGeral + espacamentoRC1 * 7 + 45;
              if (rowsClassePDFCor.length > 0) {
                doc.text('Posição e Rendimento por Classe', 6, espacoGeral);
              }
              espacoGeral = espacoGeral + 3;
              if (rowsClassePDFCor.length > 0) {
                autoTable(doc, {
                  margin: { left: 5 },
                  startY: espacoGeral,
                  tableWidth: 131,
                  headStyles: { halign: 'right', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 7,
                  },
                  columnStyles: { 1: { halign: 'left' } },
                  head: [
                    [
                      '',
                      { content: columnsClasse[0].label, styles: { halign: 'left' } },
                      columnsClasse[1].label,
                      columnsClasse[2].label,
                      columnsClasse[3].label,
                      columnsClasse[4].label,
                    ],
                  ],
                  body: rowsClassePDFCor,
                });
              }
              espacoGeral = espacamentoRC3 * 7 + 76;
              finalY = (doc as any).lastAutoTable.finalY;
              if (rowsMovimPDF.length > 0) {
                doc.text('Resumo de Movimentações', 141, espacoGeral);
              }
              espacoGeral = espacoGeral + 3;
              if (rowsMovimPDF.length > 0) {
                autoTable(doc, {
                  margin: { left: 140 },
                  startY: espacoGeral,
                  tableWidth: 64,
                  headStyles: {
                    halign: 'right',
                    textColor: theme.titleFontColor,
                    minCellHeight: 8,
                  },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle', minCellHeight: 7 },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                  },
                  columnStyles: { 0: { halign: 'left' } },
                  head: [
                    [
                      { content: columnsMovim[0].label, styles: { halign: 'left' } },
                      columnsMovim[1].label,
                      columnsMovim[2].label,
                    ],
                  ],
                  body: rowsMovimPDF,
                });
              }
              espacoGeral = finalY;
              finalY = (doc as any).lastAutoTable.finalY;
              if (espacoGeral > finalY) {
                finalY = espacoGeral;
              }
              espacoGeral = finalY + 10;
              const maxEspacoGeralPizza = Math.max(rowsLegendaPizza1.length, rowsLegendaPizza2.length) <= 3 ? 200 : 185;
              if (espacoGeral >= maxEspacoGeralPizza) {
                doc.addPage();
                espacoGeral = 18;
              }
              const catchImagem1 = await adicionarSvgPDF(
                doc,
                imgData[1],
                20,
                espacoGeral - 10,
                72,
                88,
              );
              const catchImagem2 = await adicionarSvgPDF(
                doc,
                imgData[2],
                120,
                espacoGeral - 10,
                72,
                88,
              );
              if (typeof catchImagem1 != 'number' && pieChartClassData && pieChartClassData.length > 0 && !isNaN(pieChartClassData[0].value)) {
                doc.text('Alocação por Classe', 42, espacoGeral);
              }
              if (typeof catchImagem2 != 'number' && pieChartCustodianteData && pieChartCustodianteData.length > 0 && !isNaN(pieChartCustodianteData[0].value)) {
                doc.text('Alocação por Custodiante', 138, espacoGeral);
              }
              if (typeof catchImagem1 === 'number' && typeof catchImagem2 === 'number') {
                espacoGeral = catchImagem1;
              }
              if (typeof catchImagem1 != 'number' && pieChartClassData && pieChartClassData.length > 0 && !isNaN(pieChartClassData[0].value)) {
                autoTable(doc, {
                  margin: { left: 20 },
                  startY: espacoGeral + 65,
                  tableWidth: 70,
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'plain',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 5,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    minCellHeight: 0.1,
                  },
                  columnStyles: { 1: { halign: 'left' } },
                  body: rowsLegendaPizza1,
                });
              }
              finalY2 = (doc as any).lastAutoTable.finalY;
              if (typeof catchImagem2 != 'number' && pieChartCustodianteData && pieChartCustodianteData.length > 0 && !isNaN(pieChartCustodianteData[0].value)) {
                autoTable(doc, {
                  margin: { left: 120 },
                  startY: espacoGeral + 65,
                  tableWidth: 70,
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'plain',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 4,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    minCellHeight: 0.1,
                  },
                  columnStyles: { 1: { halign: 'left' } },
                  body: rowsLegendaPizza2,
                });
              }
              finalY = (doc as any).lastAutoTable.finalY;
              if ((finalY ?? 0) > (finalY2 ?? 0)) {
                espacoGeral = finalY + 10;
              } else {
                if(finalY2 !== undefined){
                  espacoGeral = finalY2 + 10;
                }
              }
              /////////////////////////////////////////////////////////////////////////////////////////////////////PH
              if (espacoGeral >= 228) {
                doc.addPage();
                espacoGeral = 13;
              }
              espacoGeral = espacoGeral + 10;
              doc.setFontSize(14);
              doc.text('Performance Histórica', 6, espacoGeral);
              doc.setFontSize(8);
              espacoGeral = espacoGeral + 10;
              if (rowsPHCompletoPDF.length > 0) {
                doc.text('Retornos Nominais da Carteira x Benchmarks', 6, espacoGeral);
              }
              espacoGeral = espacoGeral + 3;
              if (rowsPHCompletoPDF.length > 0) {
                autoTable(doc, {
                  margin: { left: 4.5 },
                  tableWidth: 200.5,
                  startY: espacoGeral,
                  headStyles: { halign: 'center', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 8,
                  },
                  columnStyles: { 1: { halign: 'left' } },
                  head: [
                    [
                      { content: columnsRetornosPH[0].label, styles: { halign: 'left' } },
                      columnsRetornosPH[1].label,
                      columnsRetornosPH[2].label,
                      columnsRetornosPH[3].label,
                      columnsRetornosPH[4].label,
                      columnsRetornosPH[5].label,
                      columnsRetornosPH[6].label,
                      columnsRetornosPH[7].label,
                      columnsRetornosPH[8].label,
                      columnsRetornosPH[9].label,
                      columnsRetornosPH[10].label,
                      columnsRetornosPH[11].label,
                      columnsRetornosPH[12].label,
                      columnsRetornosPH[13].label,
                      columnsRetornosPH[14].label,
                      columnsRetornosPH[15].label,
                    ],
                  ],
                  body: rowsPHCompletoPDF,
                });
              }
              finalY = (doc as any).lastAutoTable.finalY;
              espacoGeral = finalY + 15;
              if (espacoGeral >= 248) {
                doc.addPage();
                espacoGeral = 23;
              }
              if (rowsMovimPHPDF.length > 0) {
                doc.text('Aplicações e Resgates', 6, espacoGeral);
              }
              espacoGeral = espacoGeral + 3;
              if (rowsMovimPHPDF.length > 0) {
                autoTable(doc, {
                  margin: { left: 5 },
                  tableWidth: 200,
                  startY: espacoGeral,
                  headStyles: { halign: 'right', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 8,
                  },
                  columnStyles: { 0: { halign: 'left' } },
                  head: [
                    [
                      { content: columnsMovimPH[0].label, styles: { halign: 'left' } },
                      columnsMovimPH[1].label,
                      columnsMovimPH[2].label,
                      columnsMovimPH[3].label,
                      columnsMovimPH[4].label,
                      columnsMovimPH[5].label,
                      columnsMovimPH[6].label,
                      columnsMovimPH[7].label,
                    ],
                  ],
                  body: rowsMovimPHPDF,
                });
              }
              finalY = (doc as any).lastAutoTable.finalY;
              espacoGeral = finalY + 15;
              if (espacoGeral >= 221) {
                doc.addPage();
                espacoGeral = 20;
              }
              // adicionar grafico linha
              const catchImagem3 = await adicionarSvgPDF(
                doc,
                imgData[3],
                5,
                espacoGeral,
                105,
                44.6,
              );
              // adicionar grafico area
              const catchImagem4 = await adicionarSvgPDF(
                doc,
                imgData[4],
                105,
                espacoGeral,
                105,
                48,
              );
              if (typeof catchImagem3 != 'number') {
                doc.text('Retorno Acumulado', 6, espacoGeral - 3);
              }
              if (typeof catchImagem4 != 'number') {
                doc.text('Evolução do Patrimônio', 106, espacoGeral - 3);
              }
              if (typeof catchImagem3 === 'number' && typeof catchImagem4 === 'number') {
                espacoGeral = catchImagem3;
              }
              // adicionar legenda para o grafico de linha
              if (typeof catchImagem3 != 'number') {
                autoTable(doc, {
                  margin: { left: 17 },
                  startY: espacoGeral + 47,
                  tableWidth: 70,
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'plain',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    minCellHeight: 0.1,
                    cellPadding: [1.5, 0],
                  },
                  body: rowsLegendaLinha,
                });
              }
              finalY2 = (doc as any).lastAutoTable.finalY;
              // adicionar legenda para o grafico de area
              if (typeof catchImagem4 != 'number') {
                autoTable(doc, {
                  margin: { left: 125 },
                  startY: espacoGeral + 47,
                  tableWidth: 70,
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'plain',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    minCellHeight: 0.1,
                    cellPadding: [1.5, 0],
                  },
                  body: rowsLegendaArea  ,
                });
              }
              espacoGeral = finalY2 + 10;
              if (espacoGeral >= 238) {
                doc.addPage();
                espacoGeral = 18;
              }
              // adicionar grafico BARRA
              const catchImagem5 = await adicionarSvgPDF(
                doc,
                imgData[5],
                5,
                espacoGeral,
                195,
                65,
              );
              if (typeof catchImagem5 === 'number') {
                espacoGeral = catchImagem5;
              }
              espacoGeral = espacoGeral + 5;
              if (typeof catchImagem5 != 'number') {
                doc.text('Ganho Financeiro (últimos 12m)', 6, espacoGeral);
              }
              espacoGeral = espacoGeral + 65;
              /////////////////////////////////////////////////////////////////////////////////////////////////////PC
              if (rowsDadosAgrupadosPDFCor || rowsRetornosAtivosPDFCor) {
                if (espacoGeral >= 238) {
                  doc.addPage();
                  espacoGeral = 13;
                }
                espacoGeral = espacoGeral + 15;
                doc.setFontSize(14);
                doc.text('Posição Consolidada', 6, espacoGeral);
                espacoGeral = espacoGeral + 5;
                if (rowsDadosAgrupadosPDFCor) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 200,
                    startY: espacoGeral,
                    headStyles: { halign: 'center', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 4,
                    },
                    columnStyles: { 0: { halign: 'left' } },
                    head: [
                      [
                        { content: columnsDadosAgrupados[0].label, styles: { halign: 'left' } },
                        columnsDadosAgrupados[1].label,
                        columnsDadosAgrupados[2].label,
                        columnsDadosAgrupados[3].label,
                        columnsDadosAgrupados[4].label,
                        columnsDadosAgrupados[5].label,
                        columnsDadosAgrupados[6].label,
                        columnsDadosAgrupados[7].label,
                        columnsDadosAgrupados[8].label,
                        columnsDadosAgrupados[9].label,
                        columnsDadosAgrupados[10].label,
                      ],
                    ],
                    body: rowsDadosAgrupadosPDFCor,
                  });
                }
                finalY = (doc as any).lastAutoTable.finalY;
                espacoGeral = finalY + 15;
                if (espacoGeral >= 268) {
                  doc.addPage();
                  espacoGeral = 18;
                }
                doc.setFontSize(8);
                if (rowsRetornosAtivosPDFCor) {
                  doc.text('Retornos Nominais por Ativo', 6, espacoGeral);
                }
                if (rowsRetornosAtivosPDFCor) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 200,
                    startY: espacoGeral + 5,
                    headStyles: { halign: 'center', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left' } },
                    head: [
                      [
                        { content: columnsRetornosPC[0].label, styles: { halign: 'left' } },
                        columnsRetornosPC[1].label,
                        columnsRetornosPC[2].label,
                        columnsRetornosPC[3].label,
                        columnsRetornosPC[4].label,
                        columnsRetornosPC[5].label,
                        columnsRetornosPC[6].label,
                        columnsRetornosPC[7].label,
                        columnsRetornosPC[8].label,
                        columnsRetornosPC[9].label,
                        columnsRetornosPC[10].label,
                      ],
                    ],
                    body: rowsRetornosAtivosPDFCor,
                  });
                }
              }
              finalY = (doc as any).lastAutoTable.finalY;
              espacoGeral = finalY + 10;
              /////////////////////////////////////////////////////////////////////////////////////////////////////Carteira Explodida
              // if (espacoGeral >= 210) { // verifica se deve criar uma nova página
              //   doc.addPage();
              //   espacoGeral = 5;
              // }
              // espacoGeral = espacoGeral + 10; // espaço entre começo da página e título
              // doc.setFontSize(14);
              // doc.text('Carteira Explodida', 6, espacoGeral);
              // doc.setFontSize(8);
              // espacoGeral = espacoGeral + 15; // espaço entre título e gráfico
              // const catchImagem6 = await adicionarSvgPDF(
              //   doc,
              //   imgData[6],
              //   70,
              //   espacoGeral - 10,
              //   72,
              //   88,
              // );
              // if (typeof catchImagem6 != 'number') {
              //   doc.text('Alocação por Classe (exposição final)', 82, espacoGeral);
              // }
              // if (typeof catchImagem6 === 'number') {
              //   espacoGeral = catchImagem6;
              // }
              // if (typeof catchImagem6 != 'number') {
              //   autoTable(doc, {
              //     margin: { left: 70 },
              //     startY: espacoGeral + 65,
              //     tableWidth: 70,
              //     bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
              //     theme: 'plain',
              //     styles: {
              //       overflow: 'linebreak',
              //       fontSize: 5,
              //       fillColor: '#FFFFFF',
              //       textColor: '#646e7b',
              //       minCellHeight: 0.1,
              //     },
              //     columnStyles: { 1: { halign: 'left' } },
              //     body: rowsLegendaPizzaCartExp,
              //   });
              // }
              // finalY = (doc as any).lastAutoTable.finalY;
              // espacoGeral = finalY + 3;
              // autoTable(doc, {
              //   margin: { left: 10 },
              //   tableWidth: 190,
              //   startY: espacoGeral + 5,
              //   headStyles: { halign: 'right', textColor: theme.titleFontColor },
              //   bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
              //   theme: 'striped',
              //   styles: {
              //     overflow: 'linebreak',
              //     fontSize: 6,
              //     fillColor: '#FFFFFF',
              //     textColor: '#646e7b',
              //     halign: 'right',
              //     minCellHeight: 7,
              //   },
              //   columnStyles: { 0: { halign: 'left' } },
              //   head: [
              //     [
              //       { content: columnsCarteiraExplodidaPDF[0].label, styles: { halign: 'left' } },
              //       columnsCarteiraExplodidaPDF[1].label,
              //       columnsCarteiraExplodidaPDF[2].label,
              //     ],
              //   ],
              //   body: rowsDadosAgrupadosCartExpPDFCor,
              // });
              // finalY = (doc as any).lastAutoTable.finalY;
              // espacoGeral = finalY + 10;
              /////////////////////////////////////////////////////////////////////////////////////////////////////FA
              if (rowsFluxoAtivosPDF.length >= 1) {
                if (espacoGeral >= 238) {
                  doc.addPage();
                  espacoGeral = 13;
                }
                espacoGeral = espacoGeral + 15;
                doc.setFontSize(14);
                doc.text('Fluxo de Ativos', 6, espacoGeral);
                espacoGeral = espacoGeral + 10;
                doc.setFontSize(8);
                doc.text('Movimentações', 6, espacoGeral);
                autoTable(doc, {
                  margin: { left: 5 },
                  tableWidth: 190,
                  startY: espacoGeral + 5,
                  headStyles: { halign: 'right', textColor: theme.titleFontColor },
                  bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                  theme: 'striped',
                  styles: {
                    overflow: 'linebreak',
                    fontSize: 6,
                    fillColor: '#FFFFFF',
                    textColor: '#646e7b',
                    halign: 'right',
                    minCellHeight: 7,
                  },
                  columnStyles: { 0: { halign: 'left' } },
                  head: [
                    [
                      { content: columnsFluxoAtivos[0].label, styles: { halign: 'left' } },
                      columnsFluxoAtivos[1].label,
                      columnsFluxoAtivos[2].label,
                      columnsFluxoAtivos[3].label,
                      columnsFluxoAtivos[4].label,
                      columnsFluxoAtivos[5].label,
                    ],
                  ],
                  body: rowsFluxoAtivosPDF,
                });
                finalY = (doc as any).lastAutoTable.finalY;
                espacoGeral = finalY + 10;
              }
              /////////////////////////////////////////////////////////////////////////////////////////////////////EA
              if (
                rowsInfos1PDF.length > 0 ||
                rowsInfos2PDF.length > 0 ||
                rowsRetornosPDF.length > 0 ||
                rowsVolPDF.length > 0 ||
                rowsSharpePDF.length > 0
              ) {
                if (espacoGeral >= 188) {
                  doc.addPage();
                  espacoGeral = 13;
                }
                espacoGeral = espacoGeral + 15;
                doc.setFontSize(14);
                doc.text('Estatísticas Avançadas', 6, espacoGeral);
                espacoGeral = espacoGeral + 10;
                doc.setFontSize(8);
                if (rowsInfos1PDF.length > 0 || rowsInfos2PDF.length > 0) {
                  doc.text('Informações Adicionais', 6, espacoGeral);
                }
                espacoGeral = espacoGeral + 3;
                if (rowsInfos1PDF.length > 0) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 90,
                    startY: espacoGeral,
                    headStyles: { halign: 'right', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left', cellWidth: 45 } },
                    head: [
                      [
                        { content: columnsInfos1[0].label, styles: { halign: 'left' } },
                        columnsInfos1[1].label,
                        columnsInfos1[2].label,
                      ],
                    ],
                    body: rowsInfos1PDF,
                  });
                }
                if (rowsInfos2PDF.length > 0) {
                  autoTable(doc, {
                    margin: { left: 105 },
                    tableWidth: 90,
                    startY: espacoGeral,
                    headStyles: { halign: 'right', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left' } },
                    head: [
                      [
                        { content: columnsInfos2[0].label, styles: { halign: 'left' } },
                        columnsInfos2[1].label,
                        columnsInfos2[2].label,
                      ],
                    ],
                    body: rowsInfos2PDF,
                  });
                }
                finalY = (doc as any).lastAutoTable.finalY;
                espacoGeral = finalY + 8;
                if (espacoGeral >= 268) {
                  doc.addPage();
                  espacoGeral = 28;
                }
                if (rowsRetornosPDF.length > 0) {
                  doc.text('Retorno', 6, espacoGeral);
                }
                espacoGeral = espacoGeral + 3;
                if (rowsRetornosPDF.length > 0) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 190,
                    startY: espacoGeral,
                    headStyles: { halign: 'right', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left', cellWidth: 30 } },
                    head: [
                      headersEstats.map((value, i) => {
                        if (i === 0) return { content: value, styles: { halign: 'left' } };
                        return value;
                      }),
                    ],
                    body: rowsRetornosPDF,
                  });
                }
                finalY = (doc as any).lastAutoTable.finalY;
                espacoGeral = finalY + 8;
                if (espacoGeral >= 268) {
                  doc.addPage();
                  espacoGeral = 28;
                }
                if (rowsVolPDF.length > 0) {
                  doc.text('Volatilidade', 6, espacoGeral);
                }
                espacoGeral = espacoGeral + 3;
                if (rowsVolPDF.length > 0) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 190,
                    startY: espacoGeral,
                    headStyles: { halign: 'right', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left', cellWidth: 30 } },
                    head: [
                      headersEstats.map((value, i) => {
                        if (i === 0) return { content: value, styles: { halign: 'left' } };
                        return value;
                      }),
                    ],
                    body: rowsVolPDF,
                  });
                }
                finalY = (doc as any).lastAutoTable.finalY;
                espacoGeral = finalY + 8;
                if (espacoGeral >= 268) {
                  doc.addPage();
                  espacoGeral = 28;
                }
                if (rowsSharpePDF.length > 0) {
                  doc.text('Índice Sharpe', 6, espacoGeral);
                }
                espacoGeral = espacoGeral + 3;
                if (rowsSharpePDF.length > 0) {
                  autoTable(doc, {
                    margin: { left: 5 },
                    tableWidth: 190,
                    startY: espacoGeral,
                    headStyles: { halign: 'right', textColor: theme.titleFontColor },
                    bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
                    theme: 'striped',
                    styles: {
                      overflow: 'linebreak',
                      fontSize: 6,
                      fillColor: '#FFFFFF',
                      textColor: '#646e7b',
                      halign: 'right',
                      minCellHeight: 6,
                    },
                    columnStyles: { 0: { halign: 'left', cellWidth: 30 } },
                    head: [
                      headersEstats.map((value, i) => {
                        if (i === 0) return { content: value, styles: { halign: 'left' } };
                        return value;
                      }),
                    ],
                    body: rowsSharpePDF,
                  });
                }
              }

              espacoGeral = 0;

              let relatDet = 'Carteira: ' + (alias_portfolio ?? carteira);
              const totalPages = doc.getNumberOfPages();
              for (let i = 2; i <= totalPages; i++) {
                if (i == totalPages) {
                  relatDet = 'Powered by Comdinheiro | ' + relatDet
                }
                doc.setPage(i);
                adicionarImagemPDF(doc, LogoPDF, 6, 2, 30, 7, recur, espacoGeral);
                doc.setFontSize(7);
                doc.text('pg ' + (i - 1) + '/' + (totalPages - 1), 194, 292);
                doc.setFontSize(6);
                doc.text(
                  '___________________________________________________________________________________________________________________________________________________________________________________',
                  0,
                  11,
                );
                autoTable(doc, {
                  margin: { left: 80 },
                  tableWidth: 130,
                  startY: 0,
                  bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                  theme: 'plain',
                  styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                  head: [],
                  body: [[relatDet]],
                });
                autoTable(doc, {
                  margin: { left: 80 },
                  tableWidth: 130,
                  startY: 3,
                  bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                  theme: 'plain',
                  styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                  head: [],
                  body: [['Data de elaboração: ' + dataElaboracaoRodape]],
                });
                autoTable(doc, {
                  margin: { left: 80 },
                  tableWidth: 130,
                  startY: 6,
                  bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                  theme: 'plain',
                  styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                  head: [],
                  body: [[
                    'Período de análise: de ' +
                    datasIni[0] +
                    '/' +
                    datasIni[1] +
                    '/' +
                    datasIni[2] +
                    ' a ' +
                    datasFim[0] +
                    '/' +
                    datasFim[1] +
                    '/' +
                    datasFim[2]
                  ]],
                });
              }

              if (recur) {
                if (obj_datas.data_ini && obj_datas.data_fim) {
                  const nome_arquivo = `Extrato_${carteira + data_iniPDF + data_fimPDF + dataElaboracao}.pdf`;
                  const prefix = props.isViaURL && process.env.REACT_APP_PDFNAME ? `${process.env.REACT_APP_PDFNAME}.` : '';  // adicionar prefixo com o nome do cliente(Utilizado no agendamento para evitar nomes duplicados)
                  doc.save(prefix + nome_arquivo);
                } else if (anoElaboracao && mesElaboracao && diaElaboracao) {
                  doc.save('Extrato' + dataElaboracao + '.pdf');
                } else {
                  doc.save('Extrato.pdf');
                }
                setGerandoPDF(false);
                const url = `${state}${carteira !== params.carteira ? '' : search}`;
                if(state && url){
                  navigate(url);
                }
              }
            }
      }
    });
  };

  /** Objeto com o campo para os cinco gráficos, indicando se determinado gráfico terminou de carregar */
  const chartLoaded = useRef<{[key: string]: boolean;}>({
    'area': false,
    'barra': false,
    'linha': false,
    'pizza1': pieChartClassData && pieChartClassData.length > 0 && !isNaN(pieChartClassData[0].value) ? false : true,
    'pizza2': pieChartCustodianteData && pieChartCustodianteData.length > 0 && !isNaN(pieChartCustodianteData[0].value) ? false : true
  });

  /** Estado para indicar se todos os gráficos terminaram de carregar */
  const [isChartLoaded, setIsChartLoaded] = useState(false);

  /**
   * Verifica se os todos os gráficos terminaram a animação
   * @param chartLoaded 'current' do objeto
   */
  function checkAllLoaded(chartLoaded: any){
      const allLoaded = Object.values(chartLoaded).every(item => item); // verifica se todos os 'value' do objeto são true
      if(allLoaded){
        setIsChartLoaded(true);
      }
  }

  /**
   * Executa essa função no callback do recharts quando termina as animações do gráfico
   * @param nome_grafico Qual gráfico vai executar essa função
   */
  function handleGraficoAnimationEnd(nome_grafico: string) {
    chartLoaded.current[nome_grafico] = true;
    checkAllLoaded(chartLoaded.current);
  }

  /** Quando todos os gráficos terminarem suas animações, gera o PDF */
  useEffect(() => {
    if(isChartLoaded){
      generatePDFAll(true);
    }
  }, [isChartLoaded])

  return (
    <>
      <LoadingModal loading={gerandoPDF ?? false} showMinimizar={false}/>
      <div>
        <Grid
          height={3000}
          style={{ color: theme.titleFontColor, fontSize: '30px', fontStyle: 'bold' }}>
          Gerando PDF...
        </Grid>
        <ResumoCarteira showCards={true} showPieChart={true} dispatchPDF={true} />
        <Grid height={50}></Grid>
        <PerformanceHistorica dispatchPDF={true} />
        <Grid height={50}></Grid>
        <div>
          <Grid container columns={3} overflow={'visible'} ref={containerRefRC0} style={{ fontFamily: 'OpenSans-Bold, OpenSans-Regular', letterSpacing:'0.03em' }}>
            {/* Letter-spacing para corrigir bug de custom font do jsPDF(as palavras se sobrepõe) */}
            <CardsPDF cards={cardsData} />
          </Grid>
        </div>
        <Grid height={50}></Grid>
        <div ref={containerRefPH1}>
          <Grid style={{ width: '600px', fontSize: '16px'}}>
            <GraficoLinha
              data={data}
              tipo={FormatTypes.percentage}
              assets={assetsApi}
              moneyPrefix={moneyPrefix}
              handleOnAnimationEnd = {() => handleGraficoAnimationEnd("linha")}
            />
          </Grid>
        </div>
        <Grid height={50}></Grid>
        <div ref={containerRefPH2}>
          <Grid style={{ width: '600px', fontSize: '16px' }}>
            <GraficoArea
              data={dataEvol}
              tipo={FormatTypes.monetary_extense}
              assets={['Carteira_retorno']}
              moneyPrefix={moneyPrefix}
              handleOnAnimationEnd = {() => handleGraficoAnimationEnd("area")}
            />
          </Grid>
        </div>
        <Grid height={50}></Grid>
        <div ref={containerRefPH3}>
          <Grid width={1200}>
            <GraficoBarra
              data={barChartRend12Data}
              decimals={2}
              moneyPrefix={moneyPrefix}
              colors={theme.barChartColor}
              height={300}
              isPercentToggle={false}
              dispatchPDF={true}
              handleOnAnimationEnd = {() => handleGraficoAnimationEnd("barra")}
            />
          </Grid>
        </div>
        <div ref={containerRefPC}>
          <PosicaoConsolidada dispatchPDF={true} />
        </div>
        {/* <div ref={containerRefCE0}>
          <CarteiraExplodida dispatchPDF={true} />
        </div> */}
        {/* <Grid ref={containerRefCE1} width={400}>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart style={{ margin: 'auto' }}>
              <Pie
                data={pieChartCartExp ? pieChartCartExp : []}
                dataKey={'value'}
                startAngle={90}
                endAngle={-270}
                outerRadius={180}
                innerRadius={110}
                fill={'green'}
                cx={'50%'}
                cy={'50%'}
                labelLine={false}
                label={renderCustomizedLabel}>
                {pieChartCartExp?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    style={{ outline: 'none' }}
                    fill={theme.chartColors[index % theme.chartColors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid> */}
        <div ref={containerRefFA}>
          <FluxoAtivos ignoraMesAtual={true} dispatchPDF={true} />
        </div>
        {/* <div ref={containerRefFC}>
          <FluxoCaixa ignoraMesAtual={true} dispatchPDF={true} />
        </div> */}
        <div ref={containerRefEA}>
          <EstatisticasAvancadas dispatchPDF={true} />
        </div>
        <Grid ref={containerRefRC1} width={400}>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart style={{ margin: 'auto', fontSize: '16px' }}>
              <Pie
                onAnimationEnd = {() => handleGraficoAnimationEnd("pizza1")}
                data={pieChartClassData ? pieChartClassData : []}
                dataKey={'value'}
                startAngle={90}
                endAngle={-270}
                outerRadius={180}
                innerRadius={110}
                fill={'green'}
                cx={'50%'}
                cy={'50%'}
                labelLine={false}
                label={renderCustomizedLabel}>
                {pieChartClassData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    style={{ outline: 'none' }}
                    fill={entry.color ?? theme.chartColors[index % theme.chartColors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
        <Grid ref={containerRefRC2} width={400}>
          <ResponsiveContainer width="100%" height={600}>
            <PieChart style={{ margin: 'auto', fontSize: '16px' }}>
              <Pie
                onAnimationEnd = {() => handleGraficoAnimationEnd("pizza2")}
                data={pieChartCustodianteData ? pieChartCustodianteData : []}
                dataKey={'value'}
                startAngle={90}
                endAngle={-270}
                outerRadius={180}
                innerRadius={110}
                fill={'green'}
                cx={'50%'}
                cy={'50%'}
                labelLine={false}
                label={renderCustomizedLabel}>
                {pieChartCustodianteData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    style={{ outline: 'none' }}
                    fill={theme.chartColors[index % theme.chartColors.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Grid>
      </div>
    </>
  );
}
