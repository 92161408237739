import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledButton = styled(Button)`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-right: 10px;
  min-width: 10;
  align-self: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  color: ${({ theme }) => theme.dropSelectorColor} !important;
  background-color: ${({ theme }) => theme.backgroundColorSecondary} !important;
  filter: ${({ disabled }) => (disabled ? 'brightness(0.8)' : '')};
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
  }
  @media ${(props) => props.theme.device.mobileM} {
    font-size: ${(props) => props.theme.xsFontSize};
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.dropSelectorColor} !important;
  margin-top: 20px;
  margin-bottom: -10px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.dropSelectorColor};
`;

export const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
